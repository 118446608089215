import React, { useContext, useEffect, useState } from "react";
import TopBar from "../../Components/ProjectsProfile/TopBar";
import ProjectSumary from "../../Components/ProjectsProfile/ProjectSumary";
import Documents from "../../Components/ProjectsProfile/Documents";
import ContractorList from "../../Components/ProjectsProfile/ContractorList";
import ConsultantList from "../../Components/ProjectsProfile/ConsultantList";
import Gallery from "../../Components/ProjectsProfile/Gallery";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { formatDateRange } from "../../Components/GlobalFunctions";

const ProjectProfile = () => {
  const { projectId } = useParams();
  const { apiURL, apiHeaderJson, apiHeaderFile } = useContext(ConfigContext);
  const [profileData, setProfileData] = useState({});

  const GetProfileInfo = async () => {
    try {
      const response = await axios.get(`${apiURL}api/projects/${projectId}`);
      const data = response.data;
      if (data.success) {
        setProfileData(data.data);
      } else {
        setProfileData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetStatus = (status) => {
    if (status === 0) {
      return "Active";
    }
    if (status === 1) {
      return "In-Active";
    }
    if (status === 2) {
      return "Completed";
    }
  };
  useEffect(() => {
    GetProfileInfo();
  }, []);
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img
                src="https://admin.atctransline.in/assets/images/architecture.jpg"
                alt
                className="profile-wid-img"
              />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
            <div className="row g-4">
            <div className="col-auto">
  <div className="avatar-lg position-relative">
    <img
      src="https://admin.atctransline.in/assets/images/users/flat-design.jpg"
      alt="user-img"
      className="img-thumbnail rounded-circle user-avatar"
    />
    {/* <div className="profile-edit-icon position-absolute">
      <label
        htmlFor="profile-photo-upload"
        className="d-flex align-items-center justify-content-center"
      >
        <i className="ri-camera-fill avatar-xs ms-3 mt-3  " style={{cursor:"pointer"}} />
      </label>
      <input
        type="file"
        id="profile-photo-upload"
        accept="image/*"
        className="d-none"
        // onChange={(e) => handleImageUpload(e)}
      />
    </div> */}
  </div>
</div>


              <div className="col">
                <div className="p-2">
                  <h3 className="text-white mb-1">
                    {profileData?.project_title || "-"}
                  </h3>
                  <p className="text-white text-opacity-75">
                    {profileData?.profile_name || "-"}{" "}
                  </p>
                  <div className="hstack text-white-50 gap-1">
                    <div className="me-2">
                      <i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle" />
                      {profileData?.project_address}
                    </div>
                    <div>
                      <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle" />
                      DualSysco Research & Development
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="col-12 col-lg-auto order-last order-lg-0">
                  <div className="row text text-white-50 text-center">
                    <div className="col-lg-12 col-2 ">
                      <div className="d-flex justify-content-between align-items-center flex-nowrap">
                        <div className="p-2">
                          <h4 className="text-white mb-1">
                            {profileData?.total_consultants}
                          </h4>
                          <p className="fs-14 mb-0">Consultant</p>
                        </div>
                        <div className="p-2">
                          <h4 className="text-white mb-1">
                            {profileData?.total_contractors}
                          </h4>
                          <p className="fs-14 mb-0">Contractor</p>
                        </div>
                        <div className="p-2 text-nowrap ml-1 ">
                          <h4 className="text-white mb-1 ">
                            {formatDateRange(
                              profileData?.project_start_date,
                              profileData?.project_end_date
                            )}
                          </h4>
                          <p className="fs-14 mb-0">Duration</p>
                        </div>
                        <div className="p-2">
                          <h4 className="text-white mb-1">
                            {GetStatus(profileData?.project_status)}
                          </h4>
                          <p className="fs-14 mb-0"> Status</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-auto order-last order-lg-0">
                <div className="row text text-white-50 text-center">
                  <div className="col-lg-4 col-4"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <TopBar />

                <div className="tab-content pt-4 text-muted">
                  <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                  >
                    <div className="row">
                      <ProjectSumary />
                      <Documents />
                      <ContractorList />
                      <ConsultantList />
                      <Gallery />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="documents" role="tabpanel">
                    <Documents />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contractor"
                    role="tabpanel"
                  >
                    <ContractorList />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="consultant"
                    role="tabpanel"
                  >
                    <ConsultantList />
                  </div>
                  <div className="tab-pane fade" id="Gallery" role="tabpanel">
                    <Gallery />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">2024 © DualSysco.</div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">
                Design &amp; Develop by DualSysco Research &amp; Development
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ProjectProfile;
