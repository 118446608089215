import Dashboard from "../Pages/Dashboard/Dashboard";
import Speciality from "../Pages/Masters/Speciality";
import Consultant from "../Pages/Masters/Consultant";
import ConsultantSpeciality from "../Pages/Masters/ConsultantSpeciality";
import Invoice from "../Pages/Invoice";
import Contractor from "../Pages/Masters/Contractor";
import Project from "../Pages/Masters/Project";
import User from "../Pages/Masters/User";
import ProjectList from "../Pages/Masters/ProjectList";
import ProjectProfile from "../Pages/ProjectProfileDetail/ProjectProfile";
import Client from "../Pages/Masters/Client";
import Items from "../Pages/Masters/Items";
import ProjectProfileEdite from "../Pages/ProjectProfileDetail/ProjectProfileEdite";
import ManageDocument from "../Components/ManageProject/Document";
import ManageContractorList from "../Components/ManageProject/ContractorList";
import ManageConsultantList from "../Components/ManageProject/ConsultantList";
import ManageGallery from "../Components/ManageProject/Gallery";
import Approvals from "../Pages/Approvals/Approvals";
import RequestList from "../Pages/Approvals/RequestList";
import Profile from "../Pages/Masters/Profile";
import ManageTask from "../Components/ManageProject/Task";



// routes.js
const routes = [
  ////////////////////// Dashboard Routing ///////////////////////////
  { path: "/", element: <Dashboard /> },
  { path: "/Invoice/:order_id", element: <Invoice /> },

  ////////////////////// Master's Routing ///////////////////////////
  { path: "/Contractor", element: <Contractor /> },
  { path: "/Projects/:projectId?", element: <Project /> },
  { path: "/ProjectList", element: <ProjectList /> },
  { path: "/Client", element: <Client /> },
  { path: "/Items", element: <Items /> },
  { path: "/EditProfile/:projectId?", element: <ProjectProfileEdite /> },
  { path: "/Speciality", element: <Speciality /> },
  { path: "/Consultant", element: <Consultant /> },
  { path: "/ConsultantSpeciality", element: <ConsultantSpeciality /> },
  { path: "/Profile", element: <Profile /> },
  {path: "/User", element:<User/>},
  



  ////////////////////// Project's Routing ///////////////////////////
  { path: "/ProjectProfile/:projectId?", element: <ProjectProfile /> },
  { path: "/Documents/:projectId?", element: <ManageDocument /> },
  { path: "/ContractorList/:projectId?", element: <ManageContractorList /> },
  { path: "/ConsultantList/:projectId?", element: <ManageConsultantList /> },
  { path: "/Gallery/:projectId?", element: <ManageGallery /> },
  { path : "/Task/:projectId?", element: <ManageTask/>},



  ////////////////////// Approvals ///////////////////////////
  { path: "/Approvals", element: <Approvals /> },
  { path: "/RequestList/:project_id?", element: <RequestList /> },

];

export default routes;
