import React, { useState, useEffect, useContext } from 'react';
import PageTitle from "../../Components/PageTitle";
import { SubmitBtn } from "../../Components/InputElements";
import axios from 'axios';
import Swal from 'sweetalert2';
import { ConfigContext } from '../../Context/ConfigContext';
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import { formatDate } from "../../Components/GlobalFunctions";
import Select from "react-select"


const Consultant = () => {

    const [profile_id, setprofile_id] = useState(0)
    const { apiURL, apiHeaderJson, } = useContext(ConfigContext)
    const [profile_name, setprofile_name] = useState("");
    const [profile_about, setprofile_about] = useState("");
    const [profile_address, setprofile_address] = useState("");
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [profile_contact_number, setprofile_contact_number] = useState("");
    const [profile_category_id, setprofile_category_id] = useState("");
    const [profile_whatsapp, setprofile_whatsapp] = useState("");
    const [profile_email, setprofile_email] = useState("");
    const [category_rows, setCategoryRows] = useState([]);

    const [rows, setRows] = useState([]);
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loadingList, setLoadingList] = useState(false);
    const [errors, setErrors] = useState({});
    const [keyword, setKeyword] = useState("");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        setLoadingList(true);
        GetList();
        GetProductList();

    }, [page]);

    const handleInputChange = (e, setStates) => {
        const { name, value } = e.target;
        setStates(value);
        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null,
            }));
        }

    };

    const validateForm = () => {
        var errors = {};
        if (!profile_name.trim()) {
            errors.profile_name = 'Consultant name is required';
        } else if (!/^[A-Za-z\s]+$/.test(profile_name)) {
            errors.profile_name = 'Name must only contain letters';
        }
        if (!profile_email.trim()) {
            errors.profile_email = 'Email is required';
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(profile_email)) {
            errors.profile_email = 'Enter a valid email address';
        }
        if (!profile_contact_number.trim()) {
            errors.profile_contact_number = 'Contact number is required';
        } else if (!/^\d{10}$/.test(profile_contact_number)) {
            errors.profile_contact_number = 'Contact number must be exactly 10 digits';
        }
        if (!profile_address.trim()) {
            errors.profile_address = 'Address is required';
        }
        if (!profile_about.trim()) {
            errors.profile_about = 'About is required';
        }
        // else if(!/^[a-zA-Z0-9\s,.'-]$/.test(profile_about)){
        //     errors.profile_about = "About should not contain special symbol"
        // }
        if (!profile_whatsapp.trim()) {
            errors.profile_whatsapp = 'WhatsApp number is required';
        } else if (!/^\d{10}$/.test(profile_whatsapp)) {
            errors.profile_whatsapp = 'WhatsApp number must be exactly 10 digits';
        }
        if (Object.keys(errors).length !== 0) {
            setErrors(errors);
            return true;
        } else {
            return false;
        }
    };

    const GetInfo = (profile_id) => {
        try {
            const headers = apiHeaderJson;

            axios
                .get(`${apiURL}api/profiles/${profile_id}`, { headers })
                .then((response) => {

                    const { data, success } = response.data;
                    if (success === true) {
                        setprofile_id(data.profile_id);
                        setprofile_category_id(data.profile_category_id);
                        setprofile_name(data.profile_name);
                        setprofile_email(data.profile_email);
                        setprofile_contact_number(data.profile_contact_number);
                        setprofile_whatsapp(data.profile_whatsapp);
                        setprofile_address(data.profile_address);
                        setprofile_about(data.profile_about);
                    } else {
                        console.error("No data or incorrect data structure.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching profile:", error);
                });

        } catch (error) {
            console.log(error);

        }
    };

    const GetProductList = () => {
        const headers = apiHeaderJson;
        try {
            axios.get(`${apiURL}api/profile-categories`, {
                params: { pc_profile_type: 2 },
                headers,
            })
                .then((response) => {
                    const { data, success } = response.data;
                    if (success) {
                        setCategoryRows(
                            data

                                .map((row) => ({
                                    value: row.profile_category_id,
                                    label: row.pc_category_title,
                                }))
                        );
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };


    const GetList = () => {
        try {
            const headers = apiHeaderJson;
            axios
                .get(`${apiURL}api/profiles`, {
                    params: { profile_type: 2 },
                    headers,
                })
                .then((response) => {
                    const data = response.data;


                    if (data.success) {

                        setRows(data.data);
                        setNext(data.next);
                        setPrev(data.prev);
                        // setPage(data.page);
                        setTotalRecords(data.total_records);
                        setTotalPages(data.total_pages);
                    }
                    setLoadingList(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingList(false);
                });
        } catch (error) {
            console.log(error);
            setLoadingList(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) return;

        try {
            const headers = apiHeaderJson;
            const Body = {
                profile_name,
                profile_about,
                profile_address,
                profile_contact_number,
                profile_whatsapp,
                profile_email,
                profile_category_id,
                profile_type: '2',
                created_by: localStorage.getItem('user_id'),
                created_date: formatDate(),
            };

            const requestMethod = profile_id !== 0 ? 'put' : 'post';
            const url = `${apiURL}api/profiles${profile_id !== 0 ? `/${profile_id}` : ''}`;

            axios[requestMethod](url, Body, { headers })
                .then((response) => {
                    const { success } = response.data;

                    Swal.fire({
                        title: `<strong>${success ? 'Success' : 'Failed'}</strong>`,
                        text: response.data.message,
                        icon: success ? 'success' : 'error',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });

                    if (success) {
                        resetForm();
                        setLoadingList(true);
                        GetList();
                    } else {
                        setLoadingList(true);
                        GetList();
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {

                        const errorMessage = error.response.data.message[0];
                        if (errorMessage === "The profile email has already been taken.") {
                            Swal.fire({
                                title: 'Error',
                                text: errorMessage,
                                icon: 'error',
                                timer: 2000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            });
                        }
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'An unexpected error occurred!',
                            icon: 'error',
                            timer: 2000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                        });
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };


    const handleDelete = (id) => {
        try {
            const headers = apiHeaderJson;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete(`${apiURL}api/profiles/${id}`, { headers })
                        .then((response) => {


                            Swal.fire({
                                title: `<strong>${response.data.success ? 'Success' : 'Failed'}</strong>`,
                                text: response.data.message,
                                icon: response.data.success ? 'success' : 'error',
                                timer: 1000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            });

                            if (response.data.success) {
                                setLoadingList(true);
                                GetList();
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                                title: "<strong>Error</strong>",
                                html: error.message || error,
                                icon: 'error',
                            });
                        });
                }
            });

        } catch (error) {
            console.log(error);

        }
    };


    const handlePrev = () => {
        if (prev) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleNext = () => {
        if (next) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleChange = (e) => {
        setPage(parseInt(e.target.value, 10));
    };

    const resetForm = () => {
        setprofile_id(0);
        setprofile_category_id("")
        setprofile_name("")
        setprofile_about("")
        setprofile_address("")
        setprofile_contact_number("")
        setprofile_whatsapp("")
        setprofile_email("")
        setErrors({})
    };

    const handleSelect = (selectedOption, selected) => {
        setprofile_category_id(selectedOption ? selectedOption.value : null);
        setSelectedOptions(selected.value);
        setSelectedOptions(selected || []);
    };

    return (
        <div className="main-content">
            <div className="page-content">
                {
                    loadingList === false ? (
                        <div className="container-fluid">
                            <br />
                            <PageTitle title={`Add Consultant`} primary={`Home`} />
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex" style={{ backgroundColor: "#257180" }}>
                                            <h4 className="card-title mb-0 flex-grow-1 text-white">Consultant</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row gy-4">
                                                <div className="col-md-12">
                                                    <form onSubmit={handleSubmit} className="row">
                                                        <div className="col-md-12">
                                                            <label htmlFor="designation_title">Select Categories:</label>
                                                            <Select
                                                                onChange={handleSelect}
                                                                options={category_rows}
                                                                placeholder='Select Categories...'
                                                                value={
                                                                    profile_category_id
                                                                        ? category_rows.find((option) => option.value === profile_category_id)
                                                                        : null
                                                                }
                                                            />
                                                            {errors.profile_category_id && <div className="text-danger">{errors.profile_category_id}</div>}
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="profile_name">Consultant Name</label>
                                                            <input
                                                                type="text"
                                                                id="profile_name"
                                                                name="profile_name"
                                                                value={profile_name}
                                                                onChange={(e) => handleInputChange(e, setprofile_name)}
                                                                className={`form-control ${errors.profile_name ? 'is-invalid' : ''}`}
                                                                placeholder="Please Enter Consultant name"
                                                            />
                                                            {errors.profile_name && <div className="text-danger">{errors.profile_name}</div>}
                                                        </div>

                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="profile_email">Email-Id</label>
                                                            <input
                                                                type="text"
                                                                id="profile_email"
                                                                name="profile_email"
                                                                value={profile_email}
                                                                onChange={(e) => handleInputChange(e, setprofile_email)}
                                                                className={`form-control ${errors.profile_email ? 'is-invalid' : ''}`}
                                                                placeholder="Please Enter Email-Id"
                                                            />
                                                            {errors.profile_email && <div className="text-danger">{errors.profile_email}</div>}
                                                        </div>

                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="profile_contact_number">Contact Number</label>
                                                            <input
                                                                type="number"
                                                                id="profile_contact_number"
                                                                name="profile_contact_number"
                                                                value={profile_contact_number}
                                                                onChange={(e) => handleInputChange(e, setprofile_contact_number)}
                                                                className={`form-control ${errors.profile_contact_number ? 'is-invalid' : ''}`}
                                                                placeholder="Please Enter Contact Number"
                                                            />
                                                            {errors.profile_contact_number && <div className="text-danger">{errors.profile_contact_number}</div>}
                                                        </div>

                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="profile_whatsapp">WhatsApp Number</label>
                                                            <input
                                                                type="number"
                                                                id="profile_whatsapp"
                                                                name="profile_whatsapp"
                                                                value={profile_whatsapp}
                                                                onChange={(e) => handleInputChange(e, setprofile_whatsapp)}
                                                                className={`form-control ${errors.profile_whatsapp ? 'is-invalid' : ''}`}
                                                                placeholder="Please Enter WhatsApp Number"
                                                            />
                                                            {errors.profile_whatsapp && <div className="text-danger">{errors.profile_whatsapp}</div>}
                                                        </div>

                                                        <div className="col-md-12 mt-3">
                                                            <label for="profile_address">Address</label>
                                                            <input
                                                                type="text"
                                                                id="profile_address"
                                                                name="profile_address"
                                                                value={profile_address}
                                                                onChange={(e) => handleInputChange(e, setprofile_address)}
                                                                className={`form-control ${errors.profile_address ? 'is-invalid' : ''}`}

                                                                placeholder="Please Enter Address "
                                                            />
                                                            {errors.profile_address && <div className="text-danger">{errors.profile_address}</div>}
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="profile_about">About</label>
                                                            <textarea
                                                                id="profile_about"
                                                                name="profile_about"
                                                                value={profile_about}
                                                                onChange={(e) => handleInputChange(e, setprofile_about)}
                                                                className={`form-control ${errors.profile_about ? 'is-invalid' : ''}`}
                                                                placeholder="Please Enter About"
                                                                rows="4"
                                                            />
                                                            {errors.profile_about && <div className="text-danger">{errors.profile_about}</div>}
                                                        </div>


                                                        <div className="col-md-12" style={{ marginTop: "30px" }}>
                                                            {
                                                                profile_id !== 0 ?
                                                                    <SubmitBtn icon={`ri-save-line`} text={`Update`} type={`primary`} /> :
                                                                    <SubmitBtn text={`Save`} type={`primary`} icon={`ri-save-line`} />
                                                            }
                                                            {
                                                                profile_id ? <button style={{ float: 'right' }} type="button" className={`btn btn-light right btn-label`} onClick={resetForm} ><i className={` ri-arrow-right-line label-icon align-middle fs-16 ms-2`} />New</button> : <div></div>

                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className='card'>
                                        <div className="col-md-12">
                                            <div className="card-header align-items-center d-flex" style={{ background: "#257180" }}>
                                                <h4 className="card-title mb-0 flex-grow-1 text-white">Consultant List</h4>
                                            </div>
                                            <div className="card-header">
                                                <div className="pt-0 pb-0" style={{ border: "none" }}>
                                                    <div className="row py-4 pt-2">
                                                        <div className="col-md-5">
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="keyword"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="button-addon2"
                                                                    placeholder="Please Enter Keyword"
                                                                    value={keyword}
                                                                    onChange={handleInputChange}
                                                                />
                                                                <span
                                                                    className="mdi mdi-magnify search-widget-icon"
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "10px",
                                                                        top: "50%",
                                                                        transform: "translateY(-50%)",
                                                                        fontSize: "4vh",
                                                                        color: "#B1B1B6",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-5">
                                                            <div className="row">
                                                                <div className="col-md-3"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Add overflow to table container */}
                                                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                                                    <table className="table table-bordered table-striped">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className="text-center" style={{ width: "15%" }}>Profile Speciality</th>
                                                                <th className="text-center" style={{ width: "15%" }}>Consultant Name</th>
                                                                <th className="text-center" style={{ width: "20%" }}>Email-Id</th>
                                                                <th className="text-center" style={{ width: "10%" }}>Contact</th>
                                                                <th className="text-center" style={{ width: "10%" }}>WhatsApp</th>
                                                                <th className="text-center" style={{ width: "20%" }}>Address</th>
                                                                <th className="text-center" style={{ width: "25%" }}>About</th>
                                                                <th className="text-center" style={{ width: "150px" }}>Action</th> {/* Updated width */}
                                                            </tr>
                                                        </thead>
                                                        {rows.length !== 0 ? (
                                                            <tbody>
                                                                {rows.map((row) => (
                                                                    <tr key={row.profile_id}>
                                                                        <td className="text-center">{row.pc_category_title}</td>
                                                                        <td className="text-center">{row.profile_name}</td>
                                                                        <td className="text-center">{row.profile_email}</td>
                                                                        <td className="text-center">{row.profile_contact_number}</td>
                                                                        <td className="text-center">{row.profile_whatsapp}</td>
                                                                        <td className="text-center">{row.profile_address}</td>
                                                                        <td className="text-center">{row.profile_about}</td>
                                                                        <td className="d-flex justify-content-around">
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => GetInfo(row.profile_id)}
                                                                                className="btn-icon btn btn-sm btn-outline-primary"
                                                                            >
                                                                                <i className="ri-edit-box-line"></i>
                                                                            </button>
                                                                            <button
                                                                                onClick={() => handleDelete(row.profile_id)}
                                                                                className="btn-icon btn btn-sm btn-outline-danger"
                                                                            >
                                                                                <i className="ri-delete-bin-line"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="8">
                                                                        <NoRecords />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                        <tfoot className="table-light">
                                                            <tr>
                                                                <th colSpan={8}>
                                                                    <div className="d-flex justify-content-between">
                                                                        <button disabled={!prev && !loadingList} type="button" onClick={handlePrev} className="btn btn-warning btn-label waves-effect waves-light">
                                                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" /> Previous
                                                                        </button>
                                                                        <div className="col-md-4" style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <small>Total Records: {totalRecords} | Total Pages: {totalPages} | Current Page: {page}</small>
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <select className="form-select" onChange={handleChange}>
                                                                                {Array.from({ length: totalPages }, (_, i) => (
                                                                                    <option key={i + 1} selected={page === i + 1} value={i + 1}>
                                                                                        {i + 1}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <button disabled={!next && !loadingList} type="button" onClick={handleNext} className="btn btn-primary btn-label waves-effect right waves-light">
                                                                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" /> Next
                                                                        </button>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        (
                            <ContentLoader />
                        )
                }
            </div>
        </div>
    )
}

export default Consultant