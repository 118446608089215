import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import RecentActivity from "../RecentActivity";

// Fix for default marker icon in Leaflet
const markerIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const ProjectSummary = () => {
  const [profileData, setProfileData] = useState([]);
  const { apiURL, apiHeaderJson } = useContext(ConfigContext);
  const { projectId } = useParams();
  const mapRef = useRef(null);
  const [position, setPosition] = useState([19.8852069,75.3496363]); // Default position

  const GetProfileSummary = async () => {
    try {
      const headers = apiHeaderJson;
      const response = await axios.get(`${apiURL}api/projects/${projectId}`);
      const { data, success } = response.data;
      if (success) {
        setProfileData(data);
        if (data.project_latlng) {
          const [lat, lng] = data.project_latlng.split(",").map(Number);
          setPosition([lat, lng]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetProfileSummary();
  }, []);

  useEffect(() => {
    try {
      mapRef.current.flyTo(position, mapRef.current.getZoom());
    } catch (error) {}
  }, [position, mapRef]);

  return (
    <div className="col-xxl-12">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title mb-5">Complete Your Profile</h5>
          <div className="progress animated-progress custom-progress progress-label">
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              style={{ width: "30%" }}
              aria-valuenow={30}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              <div className="label">30%</div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="row">
          <div
            className="col-md-4 d-flex justify-content-center align-items-center"
            style={{ borderRight: "2px dashed #c7c7c7" }}
          >
            <div className="ms-4 mt-3 ml-3 mb-4" style={{zIndex:'0'}}>
              <MapContainer
                whenReady={() => {}}
                whenCreated={() => {}}
                center={position}
                zoom={18}
                style={{ height: "200px", width: "300px" }}
                ref={mapRef}
              
              >
                <TileLayer
                  // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position} icon={markerIcon}>
                  <Popup>
                    Project Location. <br /> Easily customizable.
                  </Popup>
                </Marker>
              </MapContainer>
              <div className="d-flex justify-content-center">
                <Link
                  to={`https://www.google.com/maps/@${position[0]},${position[1]},627m/data=!3m1!1e3?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    type="button"
                    className="btn btn-primary btn-label waves-effect waves-light rounded-pill mt-3"
                  >
                    <i className="ri-map-pin-line label-icon align-middle rounded-pill fs-16 me-2" />{" "}
                    Get Direction
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <div className="card-header bg-light bg-gradient">
                <h6 className="card-title mb-0 text-start">Project Summary</h6>
              </div>
              <div className="table-responsive mt-2">
                <p>{profileData?.project_description}</p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6 col-md-4">
                <div className="d-flex ">
                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                      <i className="ri-mail-fill" />
                    </div>
                  </div>
                  <div className="flex-grow-1 ">
                    <p className="mb-1">Email</p>
                    <h6 className="text-truncate mb-0">
                      {" "}
                      <a href="#" className="fw-semibold">
                        {profileData?.profile_email}
                      </a>{" "}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="d-flex ">
                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                      <i className="ri-phone-line" />
                    </div>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="mb-1">Contact Number</p>
                    <a href="#" className="fw-semibold">
                      {profileData?.profile_contact_number}
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-4">
                <div className="d-flex ">
                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                      <i className="ri-whatsapp-line" />
                    </div>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="mb-1">WhatsApp </p>
                    <a href="#" className="fw-semibold">
                      {profileData?.profile_whatsapp}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecentActivity />
    </div>
  );
};

export default ProjectSummary;
