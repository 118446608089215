import React, { useState, useEffect, useContext } from 'react';
import PageTitle from "../../Components/PageTitle";
import { SubmitBtn } from "../../Components/InputElements";
import axios from 'axios';
import Swal from 'sweetalert2';
import { ConfigContext } from '../../Context/ConfigContext';
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import { formatDate } from "../../Components/GlobalFunctions";

const ConsultantSpeciality = () => {

  const [profile_category_id, setprofile_category_id] = useState(0)
  const [pc_profile_type, setpc_profile_type] = useState("");
  const [pc_category_title, setpc_category_title] = useState("");
  const { apiURL, apiHeaderJson, } = useContext(ConfigContext)
  const [rows, setRows] = useState([]);
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingList, setLoadingList] = useState(false);
  const [errors, setErrors] = useState({});
  const [keyword, setKeyword] = useState("");


  useEffect(() => {
    setLoadingList(true);
    GetList();
  }, [page]);

  const handleInputChange = (e, setStates) => {
    const { name, value } = e.target;
    setStates(value);
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }

  };
  const handleFilter = () => {

  }
  const handleClear = () => {

  }

  const validateForm = () => {
    
    var errors = {};
    if (!pc_category_title.trim()) {
      errors.pc_category_title = 'Consultant Specialty is required';
    }
    else if(!/^[a-zA-Z0-9\s]+$/.test(pc_category_title)){
      errors.pc_category_title="Speciality should not contain special characters"
    }
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return true;
    } else {
      return false;
    }
  };

  const GetInfo = (profile_category_id) => {
    try {
      const headers = apiHeaderJson;

      axios
        .get(`${apiURL}api/profile-categories/${profile_category_id}`, { headers })
        .then((response) => {

          const { data, success } = response.data;
          if (success === true) {
            setprofile_category_id(data.profile_category_id);
            setpc_category_title(data.pc_category_title);

          } else {
            console.error("No data or incorrect data structure.");
          }
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });

    } catch (error) {
      console.log(error);

    }
  };



  const GetList = () => {
    try {
      const headers = apiHeaderJson;
      axios
        .get(`${apiURL}api/profile-categories`, {
          params: { limit, page },
          headers,
        })
        .then((response) => {
          const data = response.data;
        
          if (data.success) {
            setRows(data.data);
            setNext(data.next);
            setPrev(data.prev);
            // setPage(data.page);
            setTotalRecords(data.total_records);
            setTotalPages(data.total_pages);
          }
          setLoadingList(false);
        });

    } catch (error) {
      console.log(error);

    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) return;

    try {
      const headers = apiHeaderJson;
      const Body = {
        pc_category_title,
        pc_profile_type: '2',
        created_by: localStorage.getItem('user_id'),
        created_date: formatDate(),
      };

      const requestMethod = profile_category_id !== 0 ? 'put' : 'post';
      const url = `${apiURL}api/profile-categories${profile_category_id !== 0 ? `/${profile_category_id}` : ''}`;

      axios[requestMethod](url, Body, { headers })
        .then((response) => {
          const { success } = response.data;

          Swal.fire({
            title: `<strong>${success ? 'Success' : 'Failed'}</strong>`,
            text: profile_category_id? "Speciality Updated Successfully":"New Speciality Added Successfully",
            icon: success ? 'success' : 'error',
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
          });

          if (success) {
            resetForm();
            setLoadingList(true);
            GetList();
          } else {
            setLoadingList(true);
            GetList();
          }
        });

    } catch (error) {
      console.log(error);

    }
  };


  const handleDelete = (id) => {
    try {
      const headers = apiHeaderJson;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`${apiURL}api/profile-categories/${id}`, { headers })
            .then((response) => {
           
              Swal.fire({
                title: `<strong>${response.data.success ? 'Success' : 'Failed'}</strong>`,
                text: response.data.message,
                icon: response.data.success ? 'success' : 'error',
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
              });

              if (response.data.success) {
                setLoadingList(true);
                GetList();
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "<strong>Error</strong>",
                html: error.message || error,
                icon: 'error',
              });
            });
        }
      });

    } catch (error) {
      console.log(error);

    }
  };


  const handlePrev = () => {
    if (prev) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleChange = (e) => {
    setPage(parseInt(e.target.value, 10));
  };

  const resetForm = () => {
    setprofile_category_id(0);
    setpc_category_title("")
    setErrors({})
  };


  return (
    <div className="main-content">
      <div className="page-content">
        {loadingList === false ? (
          <div className="container-fluid">
            <br />
            <PageTitle title={`Add Specialities`} primary={`Home`} />
            <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div className="card-header align-items-center d-flex" style={{ backgroundColor: "#257180" }}>
                    <h4 className="card-title mb-0 flex-grow-1 text-white">Speciality</h4>
                  </div>
                  <div className="card-body">
                    <div className="row gy-4">
                      <div className="col-md-12">
                        <form onSubmit={handleSubmit} className="row">
                          <div className="col-md-12 mt-3">
                            <label htmlFor="pc_category_title">Speciality Title:</label>
                            <input
                              type="text"
                              id="pc_category_title"
                              name="pc_category_title"
                              value={pc_category_title}
                              onChange={(e) => handleInputChange(e, setpc_category_title)}
                              className={`form-control`}
                              placeholder="Please Enter Speciality"
                            />
                            {errors.pc_category_title && (
                              <div className="text-danger">
                                {errors.pc_category_title}
                              </div>
                            )}
                          </div>

                          <div
                            className="col-md-12"
                            style={{ marginTop: "30px" }}
                          >
                            {profile_category_id !== 0 ? (
                              <SubmitBtn
                                icon={`ri-save-line`}
                                text={`Update`}
                                type={`primary`}
                              />
                            ) : (
                              <SubmitBtn
                                text={`Save`}
                                type={`primary`}
                                icon={`ri-save-line`}
                              />
                            )}
                            {profile_category_id ? (
                              <button
                                style={{ float: "right" }}
                                type="button"
                                className={`btn btn-light right btn-label`}
                                onClick={resetForm}
                              >
                                <i
                                  className={` ri-arrow-right-line label-icon align-middle fs-16 ms-2`}
                                />
                                New
                              </button>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card">
                  <div className="col-md-12">
                    <div
                      className='card-header align-items-center d-flex'
                      style={{
                        background: "#257180",
                      }}>
                      <h4 className='card-title mb-0 flex-grow-1 text-white'>Speciality List</h4>

                    </div>
                    <div className="card-header">
                      <div className=" pt-0 pb-0" style={{ border: "none" }}>
                        <div className="row py-4  pt-2">
                          <div className="col-md-4">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="keyword"
                                aria-label="Recipient's username"
                                aria-describedby="button-addon2"
                                placeholder="Please Enter Keyword"
                                value={keyword}
                                onChange={handleInputChange}
                              />
                              <span
                                className='mdi mdi-magnify search-widget-icon'
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  fontSize: "4vh",
                                  color: "#B1B1B6",
                                }}
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                      <table
                        className={`table table-bordered table-stripped`}
                      >
                        <thead className="table-light">
                          <tr>
                            <th width="88%">Speciality Title</th>
                            <th className="text-center" width="12%">
                              Action
                            </th>
                          </tr>
                        </thead>
                        {rows.length !== 0 ? (
                          <tbody>
                            {rows.map((row) => (
                              <tr key={row.profile_category_id}>
                                <td>{row.pc_category_title}</td>
                                
                                <td className="d-flex justify-content-around">
                                  <button
                                    type="button"
                                    onClick={() => GetInfo(row.profile_category_id)}
                                    className="btn-icon btn btn-sm btn-outline-primary"
                                  >
                                    <i className="ri-edit-box-line"></i>
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleDelete(row.profile_category_id)
                                    }
                                    className="btn-icon btn btn-sm btn-outline-danger"
                                  >
                                    <i className="ri-delete-bin-line"></i>
                                  </button>
                                 
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <NoRecords />
                              </td>
                            </tr>
                          </tbody>
                        )}
                        <tfoot className="table-light">
                          <tr>
                            <th colSpan={6}>
                              <div className="d-flex justify-content-between">
                                <button
                                  disabled={
                                    prev === false && loadingList === false
                                      ? true
                                      : false
                                  }
                                  type="button"
                                  onClick={() => handlePrev()}
                                  className={`btn btn-warning btn-label waves-effect waves-light`}
                                >
                                  <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" />{" "}
                                  Previous
                                </button>
                                <div
                                  className="col-md-4"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {
                                    <small>
                                      Total Records: {totalRecords} | Total
                                      Pages: {totalPages} | Current Page:{" "}
                                      {page}
                                    </small>
                                  }
                                </div>
                                <div className="col-md-2">
                                  <select
                                    className="form-select"
                                    onChange={(e) => handleChange(e)}
                                  >
                                    {Array.from(
                                      { length: totalPages },
                                      (_, i) => (
                                        <option
                                          key={i + 1}
                                          selected={page === i + 1}
                                          value={i + 1}
                                        >
                                          {i + 1}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <button
                                  disabled={
                                    next === false && loadingList === false
                                      ? true
                                      : false
                                  }
                                  type="button"
                                  onClick={() => handleNext()}
                                  className={`btn btn-primary btn-label waves-effect right waves-light`}
                                >
                                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" />{" "}
                                  Next
                                </button>
                              </div>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        ) : (
          <ContentLoader />
        )}
      </div>
    </div>
  )
}

export default ConsultantSpeciality