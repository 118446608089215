import React, { useContext, useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Link, NavLink } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import { ConfigContext } from "../../Context/ConfigContext";
import BestSellingProducts from "./BestSellingProducts";
import TopCategories from "./TopCategories";
import axios from "axios";
import StatusChart from "./StatusChart";
import DonutCharts from "./DonutCharts";

const Dashboard = () => {
  const [dash, setdash] = useState([]);
    const {apiURL,apiHeaderJson} = useContext(ConfigContext);
  

  const getOrderStatus = async ()=>{
     const headers = apiHeaderJson; 
    const response = await axios.get(`${apiURL}api/GetDashboard`,{headers}) ;
    setdash(response.data.data);

    
  }

 
  useEffect(()=>{
    getOrderStatus()
  },[])
  const today = new Date();
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col">
              <div className="h-100">
                <div className="row mb-3 pb-1">
                  <div className="col-12">
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                      <div className="flex-grow-1">
                        <h4 className="fs-16 mb-1">Good Morning, Admin !</h4>
                        <p className="text-muted mb-0">
                          Here's what's happening with your store today.
                        </p>
                      </div>
                      <div className="mt-3 mt-lg-0">
                        <form action="javascript:void(0);">
                          <div className="row g-3 mb-0 align-items-center">
                            
                            <div className="col-auto">
                              <NavLink
                                to={"/Projects"}
                                type="button"
                                className="btn btn-soft-info"
                              >
                                <i className="ri-add-circle-line align-middle me-1" />
                                Manage Project
                              </NavLink>
                            </div>
                            
                          </div>
                          {/*end row*/}
                        </form>
                      </div>
                    </div>
                    {/* end card header */}
                  </div>
                  {/*end col*/}
                </div>
                {/*end row*/}
                <div className="row">
                  <div className="col-xl-3 col-md-6">
                    {/* card */}
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                             Contractors
                            </p>
                          </div>
                          <div className="flex-shrink-0 d-none">
                            <h5 className="text-success fs-14 mb-0">
                              <i className="ri-hotel-fill fs-13 align-middle" />
                              +16.24 %
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                            
                              <span
                                className="counter-value"
                                data-target="559.25"
                              >
                                {dash.contractors}
                              
                              </span>
                              
                            </h4>
                            <Link
                              to={`/Contractor`}
                              className="text-decoration-underline text-muted"
                            >
                              View All Contractors    
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-success-subtle rounded fs-3">
                              <i className=" ri-hotel-fill text-success" />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-3 col-md-6">
                    {/* card */}
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Projects
                            </p>
                          </div>
                          <div className="flex-shrink-0 d-none">
                            <h5 className="text-danger fs-14 mb-0">
                              <i className="ri-arrow-right-down-line fs-13 align-middle" />
                              -3.57 %
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              {dash.tabs&&<span
                                className="counter-value"
                                data-target={36894}
                              >
                                {dash.tabs.total_projects.length}
                                
                              </span>}
                            </h4>
                            <Link
                              to={`/Projects`}
                              className="text-decoration-underline text-muted"
                            >
                              View All Projects
                            </Link>
                          </div>
                          <div className=" avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-info-subtle rounded fs-3">
                              <i
                                className="ri-building-2-fill text-info
"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-3 col-md-6">
                    {/* card */}
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                             Clients
                            </p>
                          </div>
                          <div className="flex-shrink-0 d-none">
                            <h5 className="text-success fs-14 mb-0">
                              <i className="ri-arrow-right-up-line fs-13 align-middle" />
                              +29.08 %
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              <span
                                className="counter-value"
                                data-target="183.35"
                              >
                                {dash.clients}
                                
                              </span>
                              
                            </h4>
                            <Link
                              to={`/Client`}
                              className="text-decoration-underline text-muted"
                            >
                              See All Clients
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-warning-subtle rounded fs-3">
                              <i className="  ri-team-fill text-warning" />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-3 col-md-6">
                    {/* card */}
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Consultant
                            </p>
                          </div>
                          <div className="flex-shrink-0 d-none">
                            <h5 className="text-muted fs-14 mb-0">+0.00 %</h5>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              
                              <span
                                className="counter-value"
                                data-target="165.89"
                              >
                                {dash.consultants}
                                
                                
                              </span>
                              
                            </h4>
                            <Link
                              to={`/Consultant`}
                              className="text-decoration-underline text-muted"
                            >
                              See All Consultants
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-primary-subtle rounded fs-3">
                              <i
                                className="  ri-user-settings-fill text-primary
"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                </div>{" "}
                {/* end row*/}
                <div className="row">
                  <div className="col-xl-7">
                    <div className="card">
                      
                      {/* end card header */}
                      <StatusChart   />
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-5">
                    {/* card */}
                    <div className="card">
                     
                     
                      <DonutCharts />
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {/* <BestSellingProducts /> */}
                  </div>
                  <div className="col-md-6">
                    {/* <TopCategories /> */}
                  </div>
                </div>
                {/* end row*/}
                {/* end row*/}
              </div>{" "}
              {/* end .h-100*/}
            </div>{" "}
            {/* end col */}
          
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

export default Dashboard;
