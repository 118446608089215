// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAg1HXyL4SfEGv1vCGUJqHSSvAqk2hAZHM",
  authDomain: "mabany-85415.firebaseapp.com",
  projectId: "mabany-85415",
  storageBucket: "mabany-85415.firebasestorage.app",
  messagingSenderId: "847429042495",
  appId: "1:847429042495:web:363bf7e26c0eed44ae938a",
  measurementId: "G-697BBM0GL3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export  const messaging = getMessaging(app);

export const generateToken = async () =>{
    const permission = await Notification.requestPermission();
    console.log(permission);
    if(permission === "granted"){
        
        const token = await getToken(messaging,{
            vapidKey:""
        })   
        console.log(token);
    }
    
}