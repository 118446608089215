import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { ConfigContext } from '../../Context/ConfigContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { formatDate } from '../GlobalFunctions';
import Swal from 'sweetalert2';
import { ContentLoader, NoRecords } from "../../Components/Shimmer";

const ConsultantList = () => {
    const [category_rows, setCategoryRows] = useState([]);
    const { apiURL, apiHeaderJson, } = useContext(ConfigContext)
    const [pc_consultant_id, setpc_consultant_id] = useState(null)
    const [consultantData, setConsultantData] = useState([]);
    const [loadingList, setloadingList] = useState(true);
    const { projectId } = useParams();


    const GetConsultantList = () => {
        const headers = apiHeaderJson;
        try {
            axios.get(`${apiURL}api/profiles`, { params: { profile_type: 2 }, headers })
                .then((response) => {
                    const { data, success } = response.data;

                    if (success) {
                        setCategoryRows(
                            data.map((row) => ({
                                value: row.profile_id,
                                label: row.profile_name,
                            }))
                        );
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    
    const handleSelect = (selectedOption) => {
        setpc_consultant_id(selectedOption ? selectedOption.value : null);
    };


    const handleAddConsultant = async () => {
        try {
            const headers = apiHeaderJson;
            const Body = {
                pc_project_id: projectId,
                pc_consultant_id,
                created_by: localStorage.getItem('user_id'),
                created_date: formatDate(),
            }
            const response = await axios.post(`${apiURL}api/project-consultants`, Body, headers)

            const { success } = response.data;

            Swal.fire({
                title: `<strong>${success ? 'Success' : 'Failed'}</strong>`,
                text: "New Consultant Added Successfully",
                icon: success ? 'success' : 'error',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
            if (success) {
                setpc_consultant_id(null);
                getConsultantInfo();
            }


        } catch (error) {
            console.log(error);

        }
    }

    const getConsultantInfo = async () => {
        try {
            setloadingList(true)
            const headers = apiHeaderJson;
            const response = await axios.get(`${apiURL}api/project-consultants/project/${projectId}`, headers)
            const { data, success } = response.data;
            setloadingList(false);
            if (success) {
                setConsultantData(data);
            }
        } catch (error) {
            setloadingList(false);
            console.log(error);
        }
    }


    const handleDelete = (id) => {
        try {
            const headers = apiHeaderJson;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete(`${apiURL}api/project-consultants/${id}`, { headers })
                        .then((response) => {

                            Swal.fire({
                                title: `<strong>${response.data.success ? 'Success' : 'Failed'}</strong>`,
                                text: response.data.message,
                                icon: response.data.success ? 'success' : 'error',
                                timer: 1000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            });

                            if (response.data.success) {
                                setConsultantData([]);
                                getConsultantInfo();
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                                title: "<strong>Error</strong>",
                                html: error.message || error,
                                icon: 'error',
                            });
                        });
                }
            });

        } catch (error) {
            console.log(error);

        }
    }


    useEffect(() => {
        GetConsultantList();
        getConsultantInfo();
    }, []);
    return (
        <div className="col-xxl-12">
            <div className="card">
                <div className="card-body">
                    <div className='card-header align-items-center d-flex'>
                        <div className="d-flex align-items-center mb-4">
                            <h5 className="card-title flex-grow-1 mb-0">Consultant List</h5>
                        </div>

                        <div className="d-flex justify-content-end align-items-center  ms-auto">
                            <form className="me-2 mb-0">
                                <Select
                                    onChange={handleSelect}
                                    options={category_rows}
                                    placeholder="Please Select Consultant"
                                    value={
                                        pc_consultant_id
                                            ? category_rows.find((option) => option.value === pc_consultant_id)
                                            : null
                                    }
                                />
                            </form>
                            <button
                                type="button"
                                className="btn btn-info btn-label waves-effect waves-light"
                                onClick={pc_consultant_id && handleAddConsultant}
                            >
                                Add <i className="bx bx-add-to-queue label-icon align-middle fs-16 "></i>
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        {
                            loadingList ?(<ContentLoader/>):
                        consultantData.length === 0 ?<NoRecords/>:
                            consultantData.map((item) => {
                                return (
                                    <div className="col-xxl-3 col-sm-6 mt-4">
                                        <div className="card profile-project-card shadow-none profile-project-info mb-3" style={{ position: 'relative' }}>
                                            <button
                                                onClick={() => handleDelete(item?.project_consultant_id)}
                                                className="btn-icon btn btn-sm btn-outline-danger"
                                                style={{ position: 'absolute', top: '10px', right: '10px' }}
                                            >
                                                <i className="ri-close-fill" style={{ fontSize: "20px" }}></i>
                                            </button>
                                            <div className="card-body p-4">
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-group-item me-2">
                                                        <div className="avatar-xs mb-5">
                                                            <img
                                                                style={{ width: '240px' }}
                                                                src="https://admin.atctransline.in/assets/images/users/avatar-8.jpg"
                                                                alt="Avatar"
                                                                className="rounded-circle img-fluid"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 text-muted">
                                                        <p className="text-muted mb-0">
                                                            Name: <span className="fw-semibold text-body">{item?.profile_name}</span>
                                                        </p>
                                                        <p className="text-muted mb-0">
                                                            Contact Number: <span className="fw-semibold text-body">{item?.profile_contact_number}</span>
                                                        </p>
                                                        <p className="text-muted mb-0">
                                                            Email-Id: <span className="fw-semibold text-body">{item?.profile_email}</span>
                                                        </p>
                                                        <p className="text-muted mb-0">
                                                            Speciality: <span className="fw-semibold text-body">{item?.pc_category_title} </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }

                    </div>
                    {/*end row*/}
                </div>
                {/*end card-body*/}
            </div>
        </div>
    )
}

export default ConsultantList