import React, { useState, useEffect, useContext } from 'react';
import PageTitle from "../../Components/PageTitle";
import { SubmitBtn } from "../../Components/InputElements";
import axios from 'axios';
import Swal from 'sweetalert2';
import { ConfigContext } from '../../Context/ConfigContext';
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import { formatDate } from "../../Components/GlobalFunctions";
import Select from "react-select"

const User = () => {

    const [user_id, setuser_id] = useState(0)

    const { apiURL, apiHeaderJson, } = useContext(ConfigContext)
    const [login_id, setlogin_id] = useState("");
    const [panel_type, setpanel_type] = useState("");
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [rows, setRows] = useState([]);
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loadingList, setLoadingList] = useState(false);
    const [errors, setErrors] = useState({});
    const [keyword, setKeyword] = useState("");
    const [login_password, setlogin_password] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [category_rows, setCategoryRows] = useState([]);
    const [user_profile_id, setuser_profile_id] = useState("")
    const [profile_id, setprofile_id] = useState(0)
    const [profile_name, setprofile_name] = useState("");

    const panel_type_rows = [

        { value: "ADMIN", label: "ADMIN" },
        { value: "CLIENT", label: "CLIENT" },
        { value: "CONTRACTOR", label: "CONTRACTOR" },
        { value: "CONSULTANT", label: "CONSULTANT" },
    ];





    const handleInputChange = (e, setStates) => {
        const { name, value } = e.target;
        setStates(value);
        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null,
            }));
        }

    };

    const validateForm = () => {
        var errors = {};


        if (!panel_type.trim()) {
            errors.panel_type = 'Panel Type is requested';
        }

        if (!login_id.trim()) {
            errors.login_id = 'Login ID is requested';
        }
        if (!login_password.trim()) {
            errors.login_password = 'Password is required';
        } else if (login_password.length < 6) {
            errors.login_password = 'Password must be at least 6 characters';
        }
        if (!confirmPassword.trim()) {
            errors.confirmPassword = 'Confirm Password is requested';
        }
        if (Object.keys(errors).length !== 0) {
            setErrors(errors);
            return true;
        } else {
            return false;
        }
    };

    const GetInfo = (user_id) => {
        const headers = apiHeaderJson;
        try {

            axios
                .get(`${apiURL}api/users/${user_id}`, { headers })
                .then((response) => {

                    const { data, status } = response;
                    if (status === 200) {
                        setuser_id(data.user_id);
                        setuser_profile_id(data.user_profile_id);
                        setlogin_id(data.login_id);
                        setlogin_password(data.login_password);
                        setpanel_type(data.panel_type);
                    } else {
                        console.error("No data or incorrect data structure.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching profile:", error);
                });
        } catch (error) {
            console.log(error);

        }
    };



    const GetList = () => {
        const headers = apiHeaderJson;
        try {
            axios
                .get(`${apiURL}api/users`, {
                    params: { limit, page },
                    headers,
                })
                .then((response) => {
                    const data = response;
                    if (data.status === 200) {
                        setRows(data.data);
                        setNext(data.next);
                        setPrev(data.prev);
                        // setPage(data.page);
                        setTotalRecords(data.total_records);
                        setTotalPages(data.total_pages);
                    }
                    setLoadingList(false);
                });

        } catch (error) {
            console.log(error);

        }
    };

    const GetProductList = () => {
        const headers = apiHeaderJson;
        try {
            axios.get(`${apiURL}api/profiles`, { headers, })
                .then((response) => {
                    const { data, success } = response.data;
                    if (success) {
                        setCategoryRows(
                            data.map((row) => ({
                                value: row.profile_id,
                                label: row.profile_name,
                            }))
                        );
                    }
                });

        } catch (error) {
            console.log(error);

        }
    };

     const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) return;

        try {
            const headers = apiHeaderJson;
            const Body = {
                user_profile_id,
                login_id,
                login_password,
                panel_type,
                created_by: localStorage.getItem('user_id'),
                created_date: formatDate(),
            };

            const requestMethod = user_id !== 0 ? 'put' : 'post';
            const url = `${apiURL}api/users${user_id !== 0 ? `/${user_id}` : ''}`;

            axios[requestMethod](url, Body, { headers })
                .then((response) => {
                    const { status, data: { message } } = response;

                    Swal.fire({
                        title: `<strong>${status === 200 || status === 201 ? 'Success' : 'Failed'}</strong>`,
                        text: message,
                        icon: status === 200 || status === 201 ? 'success' : 'error',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });

                    if (status === 200 || status === 201) {
                        resetForm();
                    }

                    setLoadingList(true);
                    GetList();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        const errors = error.response.data.errors;

                        
                        if (errors && errors.login_id && errors.login_id[0] === "The login id has already been taken.") {
                            Swal.fire({
                                title: 'Error',
                                text: "The login ID has already been taken.",
                                icon: 'error',
                                timer: 2000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            });
                        } else {
                                
                            Swal.fire({
                                title: 'Error',
                                text: error.response.data.message,
                                icon: 'error',
                                timer: 2000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            });
                        }
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'An unexpected error occurred!',
                            icon: 'error',
                            timer: 2000,
                            timerProgressBar: true,
                            showConfirmButton: false,
                        });
                    }
                });


        } catch (error) {
            console.log(error);
        }
    };


    const handleDelete = (id) => {
        try {
            const headers = apiHeaderJson;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete(`${apiURL}api/users/${id}`, { headers })
                        .then((response) => {

                            Swal.fire({
                                title: `<strong>${response.status === 204 ? 'Success' : 'Failed'}</strong>`,
                                text: 'User has been deleted successfully..!',
                                icon: response.status === 204 ? 'success' : 'error',
                                timer: 1000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            });

                            if (response.status === 204) {
                                setLoadingList(true);
                                GetList();
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                                title: "<strong>Error</strong>",
                                html: error.message || error,
                                icon: 'error',
                            });
                        });
                }
            });

        } catch (error) {
            console.log(error);

        }
    };


    const handlePrev = () => {
        if (prev) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleNext = () => {
        if (next) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleChange = (e) => {
        setPage(parseInt(e.target.value, 10));
    };

    const resetForm = () => {
        setuser_id(0);
        setuser_profile_id(0);
        setpanel_type("")
        setlogin_id("")
        setlogin_password("")
        setConfirmPassword("")
        setErrors({})
    };

    const handlePasswordChange = (e) => {
        setlogin_password(e.target.value);
        validatePasswordMatch(e.target.value, confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        validatePasswordMatch(login_password, e.target.value);
    };

    const validatePasswordMatch = (login_password, confirmPassword) => {
        setPasswordMatch(login_password && confirmPassword && login_password === confirmPassword);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };



   
    const handleSelect = (selectedOption, selected) => {
        setuser_profile_id(selectedOption ? selectedOption.value : null);
        setSelectedOptions(selected.value);
        setSelectedOptions(selected || []);
    };

    const handleSelect1 = (selectedOptions, action) => {
        if (action.name === "profile") {
            setuser_profile_id(selectedOptions.value);
        } else if (action.name === "panel") {
            setpanel_type(selectedOptions.value);
        }
    };

    useEffect(() => {
        setLoadingList(true);
        GetList();
        GetProductList();
    }, [page]);
    return (
        <div className="main-content">
            <div className="page-content">
                {
                    loadingList === false ? (
                        <div className="container-fluid">
                            <br />
                            <PageTitle title={`Add Users`} primary={`Home`} />
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex" style={{ backgroundColor: "#257180" }}>
                                            <h4 className="card-title mb-0 flex-grow-1 text-white">User</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row gy-4">
                                                <div className="col-md-12">
                                                    <form onSubmit={handleSubmit} className="row">
                                                        <div className="col-md-12">
                                                            <label htmlFor="designation_title">Select Profile:</label>
                                                            <Select
                                                                onChange={handleSelect}
                                                                options={category_rows}
                                                                placeholder='Select Profile...'
                                                                value={
                                                                    user_profile_id
                                                                        ? category_rows.find((option) => option.value === user_profile_id)
                                                                        : null
                                                                }
                                                            />
                                                            {errors.user_profile_id && <div className="text-danger">{errors.user_profile_id}</div>}
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="designation_title">Select Panel Type:</label>
                                                            <Select
                                                                onChange={(option) => handleSelect1(option, { name: "panel" })}
                                                                options={panel_type_rows}
                                                                placeholder='Select Panel Type...'
                                                                value={
                                                                    panel_type
                                                                        ? panel_type_rows.find((option) => option.value === panel_type)
                                                                        : null
                                                                }
                                                            />
                                                        </div>
                                                        {errors.panel_type && <div className="text-danger">{errors.panel_type}</div>}
                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="login_id">User ID</label>
                                                            <input
                                                                type="text"
                                                                id="login_id"
                                                                name="login_id"
                                                                value={login_id}
                                                                onChange={(e) => handleInputChange(e, setlogin_id)}
                                                                className={`form-control ${errors.login_id ? 'is-invalid' : ''}`}
                                                                placeholder="Please Enter User ID"
                                                            />
                                                            {errors.login_id && <div className="text-danger">{errors.login_id}</div>}
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="login_password">Password:</label>
                                                            <div className="position-relative">
                                                                <input
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    id="login_password"
                                                                    name="login_password"
                                                                    value={login_password}
                                                                    onChange={handlePasswordChange}
                                                                    className="form-control"
                                                                    placeholder="Please Enter Password"
                                                                />
                                                                <span
                                                                    onClick={togglePasswordVisibility}
                                                                    className="position-absolute top-50 end-0 translate-middle-y me-3"
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    {showPassword ? (
                                                                        <i className="ri-eye-off-line" aria-hidden="true"></i>
                                                                    ) : (
                                                                        <i className="ri-eye-line" aria-hidden="true"></i>
                                                                    )}
                                                                </span>
                                                                {errors.login_password && <div className="text-danger">{errors.login_password}</div>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="confirmPassword">Confirm Password:</label>
                                                            <div className="position-relative">
                                                                <input
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    id="confirmPassword"
                                                                    name="confirmPassword"
                                                                    value={confirmPassword}
                                                                    onChange={handleConfirmPasswordChange}
                                                                    className={`form-control ${passwordMatch === null ? '' : passwordMatch ? 'is-valid' : 'is-invalid'}`}
                                                                    placeholder="Please Re-Enter Password"
                                                                />
                                                                <span
                                                                    onClick={togglePasswordVisibility}
                                                                    className="position-absolute top-50 end-0 translate-middle-y me-3"
                                                                    style={{ cursor: 'pointer' }}
                                                                >

                                                                </span>
                                                                {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
                                                            </div>
                                                            {passwordMatch === false && <div className="text-danger">Passwords do not match</div>}
                                                        </div>




                                                        <div className="col-md-12" style={{ marginTop: "30px" }}>
                                                            {
                                                                user_id !== 0 ?
                                                                    <SubmitBtn icon={`ri-save-line`} text={`Update`} type={`primary`} /> :
                                                                    <SubmitBtn text={`Save`} type={`primary`} icon={`ri-save-line`} />
                                                            }
                                                            {
                                                                user_id !== 0 ? <button style={{ float: 'right' }} type="button" className={`btn btn-light right btn-label`} onClick={resetForm} ><i className={` ri-arrow-right-line label-icon align-middle fs-16 ms-2`} />New</button> : <div></div>

                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className='card'>
                                        <div className="col-md-12">
                                            <div
                                                className='card-header align-items-center d-flex'
                                                style={{
                                                    background: "#257180",
                                                }}>
                                                <h4 className='card-title mb-0 flex-grow-1 text-white'>Users List</h4>

                                            </div>
                                            <div className='card-header'>
                                                <div className=" pt-0 pb-0" style={{ border: "none" }}>
                                                    <div className="row py-4  pt-2">
                                                        <div className="col-md-5">
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="keyword"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="button-addon2"
                                                                    placeholder="Please Enter Keyword"
                                                                    value={keyword}
                                                                    onChange={handleInputChange}
                                                                />
                                                                <span
                                                                    className='mdi mdi-magnify search-widget-icon'
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "10px",
                                                                        top: "50%",
                                                                        transform: "translateY(-50%)",
                                                                        fontSize: "4vh",
                                                                        color: "#B1B1B6",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-5">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <table className={`table table-bordered table-stripped`}>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th className='text-center'>Profile</th>
                                                            <th className='text-center'>Panel Type</th>
                                                            <th className='text-center'>User ID</th>
                                                            <th className='text-center'>Password</th>
                                                            <th className='text-center' width={120}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    {rows.length !== 0 ? (
                                                        <tbody>
                                                            {rows.map((row) => (
                                                                <tr key={row.user_id}>
                                                                    <td className='text-center'>{row.user_profile_id}</td>
                                                                    <td className='text-center'>{row.panel_type}</td>
                                                                    <td className='text-center'>{row.login_id}</td>
                                                                    <td className='text-center'>{row.login_password}</td>

                                                                    <td className='d-flex justify-content-around'>
                                                                        <button
                                                                            type='button'
                                                                            onClick={() => GetInfo(row.user_id)}
                                                                            className="btn-icon btn btn-sm btn-outline-primary"
                                                                        >
                                                                            <i className="ri-edit-box-line"></i>
                                                                        </button>
                                                                        <button
                                                                            onClick={() => handleDelete(row.user_id)}
                                                                            className="btn-icon btn btn-sm btn-outline-danger"
                                                                        >
                                                                            <i className="ri-delete-bin-line"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="4">
                                                                    <NoRecords />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th colSpan={7} >
                                                                <div className="d-flex justify-content-between" >
                                                                    <button disabled={prev === false && loadingList === false ? true : false} type="button" onClick={() => handlePrev()} className={`btn btn-warning btn-label waves-effect waves-light`}><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" /> Previous</button>
                                                                    <div className='col-md-4' style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {
                                                                            <small>Total Records: {totalRecords} | Total Pages: {totalPages} | Current Page: {page}</small>
                                                                        }
                                                                    </div>
                                                                    <div className='col-md-2'>
                                                                        <select className="form-select" onChange={(e) => handleChange(e)}>
                                                                            {Array.from({ length: totalPages }, (_, i) => (
                                                                                <option key={i + 1} selected={page === i + 1} value={i + 1}>
                                                                                    {i + 1}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <button disabled={next === false && loadingList === false ? true : false} type="button" onClick={() => handleNext()} className={`btn btn-primary btn-label waves-effect right waves-light`}><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" /> Next</button>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        (
                            <ContentLoader />
                        )
                }
            </div>
        </div>
    )
}

export default User