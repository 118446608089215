import React, { useContext, useEffect, useState } from "react";
import { ContentLoader, NoRecords } from "../Shimmer";
import Select from "react-select";
import axios from "axios";
import { ConfigContext } from "../../Context/ConfigContext";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { useParams } from "react-router-dom";
import { DateFormater, formatDateTime, TimeFormater } from "../GlobalFunctions";
import Swal from "sweetalert2";

const Task = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [title, setTitle] = useState("");
  const [descripition, setDescripition] = useState("");
  const [profileRow, setProfileRow] = useState([]);
  const [profile_id, setprofile_id] = useState(0);
  const { apiURL, user_id } = useContext(ConfigContext);
  const [priority, setPriority] = useState(1);
  const { projectId } = useParams();
  const [taskData, setTaskData] = useState([]);
  const [projectTaskId, setProjectTaskId] = useState(null);
  const [loadingList, setloadingList] = useState(true);
  const [commentData, setCommentData] = useState([]);
  const [comment, setComment] = useState("");
  const [ptc_id, setPTC_id] = useState(0);
  const today = new Date();

  const handleSubmit = async () => {

    const Body = {
      pt_project_id: projectId,
      project_task_title: title,
      project_task_description: descripition,
      project_task_due_datetime:
        dateRange.length === 1 ? formatDateTime(dateRange[0]) :dateRange,
      project_task_assigned_to: profile_id,
      project_task_assigned_by: "1",
      project_task_priority: priority,
      project_task_assigned_datetime: formatDateTime(today),
    };
    if (projectTaskId) {
      Body.project_task_id = projectTaskId;
    }
    try {
      const requestMethod = projectTaskId ? "put" : "post";
      const response = await axios[requestMethod](`${apiURL}api/tasks`, Body);
      const data = response.data
      console.log(data,"response");
      
      
      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
        document.getElementById("close-modals")?.click();
        GetTask();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetProfiles = async () => {
    try {
      const response = await axios.get(`${apiURL}api/profiles`);
      const { data, success } = response.data;
      if (success === true) {
        setProfileRow(
          data.map((row) => ({
            value: row.profile_id,
            label: (
              <span>
                {row.profile_name} -- {row.profile_about}
              </span>
            ),
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (selectedOption) => {
    setprofile_id(selectedOption ? selectedOption.value : null);
  };
  const handlePriorityChange = (value) => {
    setPriority(value);
  };

  const GetTask = async () => {
    try {
      setloadingList(true);
      const response = await axios.get(`${apiURL}api/tasks`, {
        params: { project_id: projectId },
      });

      const { data, success } = response.data;
      setloadingList(false);
      if (success === true) {
        setTaskData(data);
      } else {
        setTaskData([]);
      }
    } catch (error) {
      setloadingList(false);
      console.log(error);
    }
  };

  const PriorityRibbion = (priority) => {
    if (priority === 1) {
      return "ribbon-two ribbon-two-info";
    }
    if (priority === 2) {
      return "ribbon-two ribbon-two-secondary";
    }
    if (priority === 3) {
      return "ribbon-two ribbon-two-warning";
    }
  };
  const PriorityText = (text) => {
    if (text === 1) {
      return "Low";
    }
    if (text === 2) {
      return "Medium";
    }
    if (text === 3) {
      return "High";
    }
  };

  const GetInfo = async (id) => {
    try {
      const response = await axios.get(`${apiURL}api/tasks`, {
        params: { project_id: projectId, project_task_id: id },
      });
      const { data, success } = response.data;
      if (success === true) {
        setProjectTaskId(data[0].project_task_id);
        setTitle(data[0].project_task_title);
        setDescripition(data[0].project_task_description);
        setprofile_id(data[0].project_task_assigned_to);
        setDateRange(data[0].project_task_due_datetime);
        setPriority(data[0].project_task_priority);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${apiURL}api/tasks`, {
            params: { project_task_id: id },
          })
          .then((response) => {
            Swal.fire({
              title: `<strong>${
                response.data.success ? "Success" : "Failed"
              }</strong>`,
              text: response.data.message,
              icon: response.data.success ? "success" : "error",
              timer: 1000,
              timerProgressBar: true,
              showConfirmButton: false,
            });

            if (response.data.success) {
              setTaskData([]);
              GetTask();
            }
          })
          .catch((error) => {
            Swal.fire({
              title: <strong>Error</strong>,
              html: error,
              icon: "error",
            });
          });
      }
    });
  };

  const GetCommets = async (pt_id) => {
    try {
      setPTC_id(pt_id);
      const response = await axios.get(`${apiURL}api/tasks/comments`, {
        params: { pt_id: pt_id },
      });
      const { data, success } = response.data;

      if (success === true) {
        setCommentData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitComment = async () => {
    const Body = {
      pt_id: ptc_id,
      pt_comment: comment,
      pt_comment_by: user_id,
    };
    try {
      const response = await axios.post(`${apiURL}api/tasks/comments`, Body);
      const { data, success } = response.data;
      if (success) {
        GetCommets(ptc_id);
        setComment("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResetFrom = () => {
    setDateRange([null, null]);
    setTitle("");
    setDescripition("");
    setprofile_id(0);
    setPriority(1);
    setProjectTaskId(null);
  };
  useEffect(() => {
    GetProfiles();
    GetTask();
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="col-xxl-12">
      <div className="card">
        <div className="card-body">
          {/* <div className="c align-items-center d-flex">
            <div className="d-flex align-items-center mb-4">
              <h5 className="card-title flex-grow-1 mb-0">Task Details</h5>
            </div>

            <div className="d-flex justify-content-end align-items-center mb-4 ms-auto">
              <button
                type="button"
                className="btn btn-secondary btn-label waves-effect waves-light"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                Add Task
                <i className="bx bx-add-to-queue label-icon align-middle fs-16 ms-2"></i>
              </button>
            </div>
          </div> */}
          <div className="card-header d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Task Details</h5>
            <div className="flex-shrink-0">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                className="btn btn-secondary btn-label waves-effect waves-light"
              >
                <i className="bx bx-add-to-queue label-icon align-middle fs-16 " />
                Add Task
              </button>
            </div>
          </div>

          {/*end row*/}
        </div>
        {/*end card-body*/}
        <div className="row">
          {loadingList ? (
            <ContentLoader />
          ) : taskData.length === 0 ? (
            <NoRecords />
          ) : (
            taskData.map((item) => {
              {console.log(taskData,"Responseof task data");
              }
              return (
                <div class="col-md-6" key={item.project_task_id}>
                  <div class="card border card-border-light ribbon-box">
                    <div
                      className="card-header d-flex justify-content-between align-items-center"
                      style={{ padding: "0px 10px" }}
                    >
                      <div
                        className={PriorityRibbion(item.project_task_priority)}
                      >
                        <span>{PriorityText(item.project_task_priority)}</span>
                      </div>
                      <div className="card-header mx-5">
                        <h6 className="card-title mb-0">
                          {item.project_task_title}
                        </h6>
                      </div>
                      <button
                        className="btn-icon btn btn-sm btn-outline-primary ms-auto"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        onClick={() => GetInfo(item.project_task_id)}
                      >
                        <i className="ri-edit-line"></i>
                      </button>
                      <button
                        className="btn-icon btn btn-sm btn-outline-danger ms-3"
                        onClick={() => handleDelete(item.project_task_id)}
                      >
                        <i className=" ri-delete-bin-5-line"></i>
                      </button>
                    </div>

                    <div class="card-body" style={{ padding: "10px" }}>
                      <p class="card-text">{item.project_task_description}</p>
                      <div className=" text-muted mt-3 d-flex justify-content-between ">
                        <span class="badge bg-primary-subtle text-primary">
                          {item.project_task_assigned_datetime} By Admin
                        </span>
                        <span class="badge bg-danger-subtle text-danger">
                          Due-Date: {item.project_task_due_datetime}
                        </span>
                      </div>
                      <div className="text-end mt-2">
                        <a
                          style={{ cursor: "pointer" }}
                          className="text-body fw-medium"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasExample"
                          aria-controls="offcanvasExample"
                          onClick={() => GetCommets(item.project_task_id)}
                        >
                          Comments
                          <i className="ri-arrow-right-line align-middle"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="myModalLabel">
                {projectTaskId ? "Update Task" : "New Task"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleResetFrom}
              ></button>
            </div>
            <div className="modal-body px-5">
              <div className="row">
                {/* <p>Please Select Project Status </p>  */}
                <div className="col-md-12">
                  <label>Title:</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    name="title"
                    className="form-control"
                  />
                </div>
                <div className="mt-3 col-md-12">
                  <label>Descripition :</label>
                  <textarea
                    type="text"
                    value={descripition}
                    name="descripition"
                    className="form-control"
                    onChange={(e) => setDescripition(e.target.value)}
                  />
                </div>
                <div className="col-md-12 mt-3 ">
                  <label>Assign to :</label>
                  <Select
                    onChange={handleSelect}
                    options={profileRow}
                    placeholder="Please Select Profile"
                    value={
                      profile_id
                        ? profileRow.find(
                            (option) => option.value === profile_id
                          )
                        : null
                    }
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <label>Expected Date</label>
                  <Flatpickr
                    data-enable-time
                    placeholder="Please Select Date"
                    className="form-control date-picker "
                    value={dateRange}
                    onChange={(selectedDates) => {
                      setDateRange(selectedDates);
                    }}
                    options={{
                      minDate: "today",
                      dateFormat: "Y-m-d H:i",
                    }}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <label>Task Priority :</label>
                  <div className="row ms-2">
                    <div className="col-md-4 form-check form-radio-warning ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="High"
                        name="priority"
                        checked={priority === 3}
                        onChange={() => handlePriorityChange(3)}
                      />

                      <label for="High">High</label>
                    </div>
                    <div className="col-md-4 form-check form-radio-secondary ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="Medium"
                        name="priority"
                        checked={priority === 2}
                        onChange={() => handlePriorityChange(2)}
                      />
                      <label for="Medium">Medium</label>
                    </div>
                    <div className="col-md-4 form-check form-radio-info ">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="Low"
                        name="priority"
                        checked={priority === 1}
                        onChange={() => handlePriorityChange(1)}
                      />
                      <label for="Low">Low</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                id="close-modals"
                onClick={handleResetFrom}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmit}
                disabled={!title || !descripition || !profile_id || !dateRange || !priority}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header border-bottom">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            All Comments
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body p-0 overflow-hidden">
          <div data-simplebar style={{ height: "calc(100vh - 112px)" }}>
            <div className="acitivity-timeline p-4">
              {
              commentData.length === 0 ? "No comments start conversation by comment":
              commentData.map((item) => {
                return (
                  <div className="acitivity-item d-flex">
                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                      <div className="avatar-title bg-success-subtle text-success rounded-circle">
                      {JSON.parse(user_id) === item.pt_comment_by ? "A": "C"}
                      </div>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-1">
                        Admin
                        {/* <span className="badge bg-primary-subtle text-primary align-middle">
                      New
                    </span> */}
                      </h6>
                      <p className="text-muted mb-2">{item.pt_comment}</p>
                      <small className="mb-0 text-muted">
                        {DateFormater(item.pt_comment_datetime)} -
                        {TimeFormater(item.pt_comment_datetime)}
                      </small>
                    </div>
                  </div>
                );
              })}

              <form className="mt-4">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                      Leave a Comments
                    </label>
                    <textarea
                      className="form-control bg-light border-light"
                      id="comment"
                      name="comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      rows={3}
                      placeholder="Enter comments"
                    />
                  </div>
                  {/*end col*/}
                  <div className="col-12 text-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => handleSubmitComment()}
                      disabled={!comment}
                    >
                      Post Comment
                    </button>
                  </div>
                </div>
                {/*end row*/}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Task;
