import React, { useState, useEffect, useContext, useCallback } from "react";
import PageTitle from "../../Components/PageTitle";

import axios from "axios";
import Swal from "sweetalert2";
import { ConfigContext } from "../../Context/ConfigContext";
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { NavLink, useParams } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { formatDate } from "../../Components/GlobalFunctions";


const RequestList = ({ customStyles }) => {
    const { apiURL, apiHeaderJson, apiHeaderFile, user_id } = useContext(ConfigContext);
    const { project_id } = useParams();
    const [rows, setRows] = useState([]);
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loadingList, setLoadingList] = useState(false);
    const [errors, setErrors] = useState({});
    const [keyword, setKeyword] = useState("");
    const [reload, setReload] = useState(false)
    const [projectItemsInfo, setProjectItemsInfo] = useState({});
    const [selectOption, setSelectOption] = useState(null);
    const [dateRange, setDateRange] = useState([null, null])

     useEffect(() => {
        setLoadingList(true);
        GetList();
    }, [page]);

 
    const GetInfo = (project_item_id) => {
        const headers = apiHeaderJson;
        axios
            .get(`${apiURL}api/project-items/items/${project_item_id}`, {
                headers,
            })
            .then((response) => {
                console.log("response", response);

                const { data, success } = response.data;
                if (success === true) {
                }
            });
    };

    const handleInputChange = (e, setStates) => {
        const { name, value } = e.target;
        setStates(value);
        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null,
            }));
        }

    };

    const GetList = () => {
        try {
            const headers = apiHeaderJson;
            axios
                .get(`${apiURL}api/project-items/items/${project_id}`, {
                    params: { limit, page, },
                    headers,
                })
                .then((response) => {
                    const data = response;


                    if (data.status === 200) {

                        setRows(data.data);
                        setNext(data.next);
                        setPrev(data.prev);
                        setPage(data.page);
                        setTotalRecords(data.total_records);
                        setTotalPages(data.total_pages);
                    }
                    setLoadingList(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingList(false);
                });
        } catch (error) {
            console.log(error);
            setLoadingList(false);
        }
    };

    const handleSetItemInfo = (data) => {
        setProjectItemsInfo(data)
    }

    const handleChangeItemInfo = (value, field) => {
        setProjectItemsInfo(prev => ({
            ...prev,
            [field]: value
        }))
    }

    const handlePrev = () => {
        if (prev) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleNext = () => {
        if (next) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleChange = (e) => {
        setPage(parseInt(e.target.value, 10));
    };


    const handleStatus = async () => {
        const { pi_item_id, pi_item_price, pi_item_qty, pi_project_id, project_item_id } = projectItemsInfo
        const headers = apiHeaderJson;
        const body = {
            project_item_id,
            pi_item_id,
            pi_item_price,
            pi_item_qty,
            pi_project_id,
            pi_approved_by: localStorage.getItem('user_id'),
            pi_approved_date: formatDate(),
            pi_status: '1',
        }

        try {
            const response = await axios.patch(`${apiURL}api/project-items/status/${project_item_id}`, body, { headers })

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Request Approved Successfully',
                    showConfirmButton: false,
                    timer: 1500
                });
                GetList();
                setProjectItemsInfo({})
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                showConfirmButton: false,
                timer: 1500
            });
        }

    };


    const handleRejectStatus = async (row) => {
        const { pi_item_id, pi_item_price, pi_item_qty, pi_project_id, project_item_id } = row;
        const headers = apiHeaderJson;
        const body = {
            project_item_id,
            pi_item_id,
            pi_item_price,
            pi_item_qty,
            pi_project_id,
            pi_approved_by: localStorage.getItem('user_id'),
            pi_approved_date: formatDate(),
            pi_status: '2',
        }
        try {
            console.log(body);
            const response = await axios.patch(`${apiURL}api/project-items/status/${project_item_id}`, body, { headers })

            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Request Rejected Successfully',
                    showConfirmButton: false,
                    timer: 1500
                });

                GetList();
                setProjectItemsInfo({})
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                showConfirmButton: false,
                timer: 1500
            });
        }

    };


    const options = [
        { value: '0', label: "Pending" },
        { value: '1', label: 'Approved' },
        { value: '2', label: 'Rejected' }
    ];

    const handleSelectStatus = (selectedOption) => {
        setSelectOption(selectedOption ? selectedOption.value : '');
    };

    const handleInputSearch = () => {
        setPage(1);
        setReload(true);
    };

    const handleClearAll = () => {
        setKeyword('');
        setSelectOption(null);

    };


    const GetStatus = (status) => {
        if (status == '0') {
            return "Pending"
        } else if (status == '1') {
            return "Approved"
        } else if (status == '2') {
            return "Rejected"
        }
    }

    const GetBadge = (status) => {
        if (status == '0') {
            return "badge bg-warning-subtle text-warning"
        } else if (status == '1') {
            return "badge bg-success-subtle text-success"
        } else if (status == '2') {
            return "badge bg-danger-subtle text-danger"
        }

    }



    useEffect(() => {
        reload && GetList()
    }, [reload])

    return (
        <div className="main-content">
            <div className="page-content">
                {loadingList === false ? (
                    <div className="container-fluid">
                        <br />
                        <PageTitle title={`Request List`} primary={`Home`} />
                        <div className="row">

                            <div className="col-md-12">
                                <div className="card">
                                    <div
                                        className='card-header align-items-center d-flex'
                                        style={{
                                            background: "#257180",
                                        }}>
                                        <h4 className='card-title mb-0 flex-grow-1 text-white'>Request List</h4>
                                        <div className="col-md-3 mx-3">
                                            <div className="row">
                                                <div className="col-md-5"></div>
                                            </div>
                                        </div>

                                        <div className='avatar-xs  flex-shrink-0'>
                                            <NavLink
                                                to={"/Approvals"}
                                                className='avatar-title bg-white rounded-circle fs-4'
                                                style={{
                                                    color: "#184c8f",
                                                }}>
                                                <i className='las la-list'></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="card-header pt-0 pb-0" style={{ border: "none" }}>
                                        <div className="row pb-2  py-4" >
                                            <div className="col-md-3">
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="keyword"
                                                        aria-label="Recipient's username"
                                                        aria-describedby="button-addon2"
                                                        placeholder="Please Enter Keyword"
                                                        value={keyword}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span
                                                        className='mdi mdi-magnify search-widget-icon'
                                                        style={{
                                                            position: "absolute",
                                                            right: "10px",
                                                            top: "50%",
                                                            transform: "translateY(-50%)",
                                                            fontSize: "4vh",
                                                            color: "#B1B1B6",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="position-relative">
                                                    <Flatpickr
                                                        placeholder="Please Select Date"
                                                        data-enable-time
                                                        className="form-control date-picker mb-3 ps-3"
                                                        value={dateRange}
                                                        onChange={(selectedDates) => {
                                                            setDateRange(selectedDates);
                                                        }}
                                                        options={{
                                                            mode: 'range',
                                                            dateFormat: 'Y-m-d',
                                                        }}
                                                    />

                                                    <span
                                                        className="ri-calendar-2-line"
                                                        style={{
                                                            position: "absolute",
                                                            right: "10px",
                                                            top: "50%",
                                                            transform: "translateY(-50%)",
                                                            fontSize: "4vh",
                                                            color: "#B1B1B6",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <Select
                                                    options={options}
                                                    value={selectOption ? options.find(option => option.value === selectOption) : null}
                                                    onChange={handleSelectStatus}
                                                    styles={customStyles}
                                                    placeholder="Please Select Status"
                                                />
                                            </div>

                                            <div className="col-md-3">
                                                <div className="row">
                                                    <div className="col-md-3">


                                                    </div>



                                                    <div className="col-md-9">

                                                        <div className="dropdown">
                                                            <button type="button" className="btn btn-light btn-label waves-effect waves-light " id="dropdownMenuLink4" data-bs-toggle="dropdown" aria-expanded="true"
                                                            >
                                                                <i className="ri-download-2-line label-icon align-middle fs-16 me-2" />
                                                                Download Report
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink4">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-printer-line me-2 align-middle text-muted" />Print</a>
                                                                </li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-file-pdf-line me-2 align-middle text-muted" />PDF</a>
                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-file-excel-2-line me-2 align-middle text-muted" />Excel</a>
                                                                    </li>

                                                                </li>
                                                            </ul>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="col-md-12">
                                        <div className="card-body pt-0">
                                            <table
                                                className={`table table-bordered table-stripped `}
                                            >
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="text-center" width="%">Item Name</th>
                                                        <th className="text-center" width="%">Quantity</th>
                                                        <th className="text-center" width="%">Price(QAR)</th>
                                                        <th className="text-center" width="%">Delivery Date</th>
                                                        <th className="text-center" width="%">Requested By</th>
                                                        <th className="text-center" width="%">Status</th>
                                                        <th className="text-center" width={"300px"}>
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {rows.length !== 0 ? (
                                                    <tbody>
                                                        {rows.map((row) => (
                                                            <tr key={row.project_item_id}>
                                                                <td className="text-center">{row.item_name}</td>
                                                                <td className="text-center">{row.pi_item_qty}</td>
                                                                <td className="text-center">{row.pi_item_price}</td>
                                                                <td className="text-center">{row.pi_request_date}</td>
                                                                <td className="text-center">{row.pi_request_by}</td>
                                                                <td className='text-center'><span className={GetBadge(row.pi_status)}>{GetStatus(row.pi_status)}</span></td>


                                                                <td className="d-flex justify-content-around">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success btn-sm btn-label waves-effect waves-light"
                                                                        disabled={row.pi_status === 1 || row.pi_status === 2}
                                                                        onClick={() => handleSetItemInfo(row)}
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#exampleModalgrid"
                                                                    >
                                                                        <i className="bx bx-check-circle label-icon align-middle fs-20 me-2"></i> Approve
                                                                    </button>

                                                                    <button
                                                                        onClick={() => handleRejectStatus(row)}
                                                                        type="button"
                                                                        className="btn btn-danger btn-sm btn-label waves-effect waves-light"
                                                                        disabled={row.pi_status === 1 || row.pi_status === 2}
                                                                    >
                                                                        <i className="bx bx-x-circle label-icon align-middle fs-20 me-2"></i> Reject
                                                                    </button>
                                                                </td>

                                                                <>

                                                                    <div
                                                                        className="modal fade"
                                                                        id="exampleModalgrid"
                                                                        tabIndex={-1}
                                                                        aria-labelledby="exampleModalgridLabel"
                                                                        aria-modal="true"
                                                                    >
                                                                        <div className="modal-dialog modal-dialog-centered">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header">
                                                                                    <h5 className="modal-title" id="exampleModalgridLabel">
                                                                                        Approve Item
                                                                                    </h5>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn-close"
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    />
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    <form action="javascript:void(0);">
                                                                                        <div className="row g-3">
                                                                                            <div className="col-md-12 mt-3">
                                                                                                <label htmlFor="category_title">Item Name:</label>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    id="category_title"
                                                                                                    name="category_title"
                                                                                                    className={`form-control`}
                                                                                                    placeholder="Please Enter Item Name"
                                                                                                    value={projectItemsInfo?.item_name}
                                                                                                    disabled
                                                                                                />
                                                                                            </div>


                                                                                            <div className="col-md-6 mt-3">
                                                                                                <label htmlFor="quantity">Quantity:</label>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    id="quantity"
                                                                                                    name="quantity"
                                                                                                    className={`form-control`}
                                                                                                    placeholder="Please Enter Quantity"
                                                                                                    value={projectItemsInfo?.pi_item_qty}
                                                                                                    onChange={(e) => handleChangeItemInfo(e.target.value, 'pi_item_qty')}
                                                                                                />
                                                                                            </div>


                                                                                            <div className="col-md-6 mt-3">
                                                                                                <label htmlFor="price">Price:</label>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    id="price"
                                                                                                    name="price"
                                                                                                    className={`form-control`}
                                                                                                    placeholder="Please Enter Price"
                                                                                                    value={projectItemsInfo?.pi_item_price}
                                                                                                    onChange={(e) => handleChangeItemInfo(e.target.value, 'pi_item_price')}
                                                                                                />
                                                                                            </div>



                                                                                            <div className="col-md-12 mt-3">
                                                                                                <label htmlFor="message-text" className="col-form-label">Reason <span style={{ color: 'gray' }}>(Optional)</span>:</label>
                                                                                                <textarea
                                                                                                    className="form-control"
                                                                                                    id="message-text"
                                                                                                    value={projectItemsInfo?.item_description}
                                                                                                    onChange={(e) => handleChangeItemInfo(e.target.value, 'item_description')}
                                                                                                />
                                                                                            </div>




                                                                                            <div className="col-lg-12">
                                                                                                <div className="hstack gap-2 justify-content-end">
                                                                                                    <button type="button" onClick={() => setProjectItemsInfo({})} className="btn btn-light" data-bs-dismiss="modal">
                                                                                                        Close
                                                                                                    </button>
                                                                                                    <button
                                                                                                        type="submit"
                                                                                                        className="btn btn-success"
                                                                                                        onClick={handleStatus}
                                                                                                        data-bs-dismiss="modal"
                                                                                                    >
                                                                                                        Approve
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>






                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="7">
                                                                <NoRecords />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                <tfoot className="table-light">
                                                    <tr>
                                                        <th colSpan={7}>
                                                            <div className="d-flex justify-content-between">
                                                                <button
                                                                    disabled={
                                                                        prev === false && loadingList === false
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    type="button"
                                                                    onClick={() => handlePrev()}
                                                                    className={`btn btn-warning btn-label waves-effect waves-light`}
                                                                >
                                                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" />{" "}
                                                                    Previous
                                                                </button>
                                                                <div
                                                                    className="col-md-4"
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        <small>
                                                                            Total Records: {totalRecords} | Total
                                                                            Pages: {totalPages} | Current Page:{" "}
                                                                            {page}
                                                                        </small>
                                                                    }
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <select
                                                                        className="form-select"
                                                                        onChange={(e) => handleChange(e)}
                                                                    >
                                                                        {Array.from(
                                                                            { length: totalPages },
                                                                            (_, i) => (
                                                                                <option
                                                                                    key={i + 1}
                                                                                    selected={page === i + 1}
                                                                                    value={i + 1}
                                                                                >
                                                                                    {i + 1}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <button
                                                                    disabled={
                                                                        next === false && loadingList === false
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    type="button"
                                                                    onClick={() => handleNext()}
                                                                    className={`btn btn-primary btn-label waves-effect right waves-light`}
                                                                >
                                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" />{" "}
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ) : (
                    <ContentLoader />
                )}
            </div>
        </div>
    );
};

export default RequestList