import React, { useContext } from "react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { Link } from "react-router-dom";

const DonutChart = () => {
 
  const { apiURL, apiHeaderJson } = useContext(ConfigContext);
  const [dash, setdash] = useState([]);
  const [rows, setRows] = useState([]);
  const [loadingList, setLoadingList] = useState(false);




  
  const GetList = () => {
    try {
        const headers = apiHeaderJson;
        axios
            .get(`${apiURL}api/GetDashboard`, {
                params: { },
                headers,
            })
            .then((response) => {
              
              const data = response.data;
             

                if (data.success) {

                    setRows(data.data.tabs.total_projects);
                }
                setLoadingList(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingList(false);
            });
    } catch (error) {
        console.log(error);
        setLoadingList(false);
    }
};

const GetStatus = (status) => {
  if (status == '0') {
      return "Pending"
  } else if (status == '1') {
      return "Assigned"
  } else if (status == '2') {
      return "Completed"
  }
}


const GetBadge = (status) => {
  if (status == '0') {
      return "badge bg-warning-subtle text-warning"
  } else if (status == '1') {
      return "badge bg-info-subtle text-info"
  } else if (status == '2') {
      return "badge bg-success-subtle text-success"
  }

}

useEffect(()=>{
  GetList();
  setLoadingList(true);

},[])

  return (
    <>
      <div className="card-header align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Active Project </h4>
      </div>


      <div
        data-simplebar="init"
        style={{ height: '80vh' }}
        className="simplebar-scrollable-y"
      >
        <div className="simplebar-wrapper" style={{ margin: 0 }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
              <div
                className="simplebar-content-wrapper"
                tabIndex={0}
                role="region"
                aria-label="scrollable content"
                style={{ height: "auto", overflow: "hidden scroll" }}
              >
                <div className="simplebar-content" style={{ padding: 0 }}>
                  {rows.length !== 0 ? (
                    rows.map((row) => (
                      <div className={`list-group ${row === 0 ? '' : ' mt-3'} px-3`} key={row.project_id}>
                        <Link to={`/ProjectProfile/${row.project_id}`} className="list-group-item list-group-item-action mt-3">
                          <div className="float-end">
                          <span className={GetBadge(row.project_status)}>{GetStatus(row.project_status)}</span>
                          </div>
                          <div className="d-flex mb-2 align-items-center">
                            <div className="flex-shrink-0">
                              <img
                                src="assets/images/users/2.jpg"
                                alt=""
                                className="avatar-sm rounded-circle"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h5 className="list-title fs-15 mb-1">{row.project_title}</h5>
                              <p className="list-text mb-0 fs-12">{row.project_description}</p>
                            </div>
                          </div>
                          <div className="row mb-3 mt-3">
                            <div className="col-8 col-md-8">
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-user-2-fill" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Contacts :</p>
                                  <h6 className="text-truncate mb-0">
                                    <a href="#" className="fw-semibold">{row.contact_details}</a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-md-4">
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-map-pin-line" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Location :</p>
                                  <a href="#" className="fw-semibold">{row.project_address}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
                      <h3 className="text-muted">No Data Found...!</h3>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 358, height: 303 }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: "hidden" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: 0, display: "none" }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: "visible" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              height: 152,
              transform: "translate3d(0px, 0px, 0px)",
              display: "block"
            }}
          />
        </div>
      </div>

    </>
  );
};

export default DonutChart;
