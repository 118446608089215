import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import ApexCharts from "apexcharts";
import { formatNumber } from "../../Components/GlobalFunctions";

const StatusChart = () => {
  const [chartData, setChartData] = useState(null);
  const { apiURL, apiHeaderJson } = useContext(ConfigContext);

  const getChart = async () => {
    try {
      const response = await axios.get(`${apiURL}api/GetDashboard`, {
        headers: apiHeaderJson,
      });
      if (response.data.success) {
        const data = response.data.data.tabs;
        setChartData({
          total_projects: data.total_projects || [],
          total_pending_projects: data.total_pending_projects || [],
          total_completed_projects: data.total_completed_projects || [],
        });
      }
    } catch (error) {
      console.error("Error loading chart data:", error);
    }
  };

  useEffect(() => {
    getChart();
  }, []);

  useEffect(() => {
    if (chartData) {
      const options = {
        series: [
          {
            name: "Ongoing Projects",
            data: chartData.total_projects.map((_, index) => index + 1),
            type: "area",
          },
          {
            name: "Completed Projects",
            data: chartData.total_completed_projects.map((_, index) => index + 1),
            type: "line",
          },
          {
            name: "Pending Projects",
            type: "bar",
            data: chartData.total_pending_projects.map((_, index) => index + 1),
          },
        ],
        chart: {
          height: 370,
          type: "line",
          toolbar: { show: false },
        },
        stroke: {
          curve: "straight",
          dashArray: [0, 0, 8],
          width: [2, 0, 2.2],
        },
        fill: {
          opacity: [0.1, 0.9, 1],
        },
        markers: {
          size: [0, 0, 0],
          strokeWidth: 2,
          hover: { size: 4 },
        },
        xaxis: {
          categories: [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
          ],
          axisTicks: { show: false },
          axisBorder: { show: false },
        },
        grid: {
          show: true,
          xaxis: { lines: { show: true } },
          yaxis: { lines: { show: false } },
          padding: { top: 0, right: -2, bottom: 15, left: 10 },
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: -5,
          markers: { width: 9, height: 9, radius: 6 },
          itemMargin: { horizontal: 10, vertical: 0 },
        },
        plotOptions: {
          bar: { columnWidth: "30%", barHeight: "70%" },
        },
        colors: ["#5cb85c", "#17a2b8", "#5bc0de"],
        tooltip: {
          shared: true,
          y: [
            { formatter: (e) => e?.toFixed(0) || e },
            { formatter: (e) => (e ? `${formatNumber(e)}` : e) },
            { formatter: (e) => (e ? `${e.toFixed(0)} ` : e) },
          ],
        },
      };

      const chart = new ApexCharts(
        document.querySelector("#customer_impression_charts"),
        options
      );
      chart.render();

      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

 
return (
  <>
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Projects Statistics</h4>
            <div>
              <button type="button" className="btn btn-soft-dark btn-sm mx-1">ALL</button>
              <button type="button" className="btn btn-soft-dark btn-sm mx-1">1M</button>
              <button type="button" className="btn btn-soft-dark btn-sm mx-1">6M</button>
              <button type="button" className="btn btn-soft-primary btn-sm mx-1">1Y</button>
            </div>
          </div>
          <div className="card-header p-0 border-0 bg-light-subtle">
            <div className="row g-0 text-center">
              <div className="col-6 col-sm-4">
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <span className="counter-value" data-target={7585}>
                      {chartData?.total_projects?.length || 0}
                    </span>
                  </h5>
                  <p className="text-muted mb-0">Pending Projects</p>
                </div>
              </div>
              <div className="col-6 col-sm-4">
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <span className="counter-value" data-target={367}>
                      {chartData?.total_pending_projects?.length || 0}
                    </span>
                  </h5>
                  <p className="text-muted mb-0">Ongoing Projects</p>
                </div>
              </div>
              <div className="col-6 col-sm-4">
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <span className="counter-value" data-target={367}>
                      {chartData?.total_completed_projects?.length || 0}
                    </span>
                  </h5>
                  <p className="text-muted mb-0">Completed Projects</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body p-0 pb-2">
      <div className="w-100">
        <div id="customer_impression_charts" className="apex-charts" dir="ltr" />
      </div>
    </div>
  </>
);

};

export default StatusChart;
