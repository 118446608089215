import React from 'react'
import TopBar from '../ProjectsProfile/TopBar'
import Gallery from '../ProjectsProfile/Gallery'
import ProjectProfileHeader from './ProfileHeader'

const ManageGallery = () => {

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <ProjectProfileHeader />
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <TopBar />

                                <div className="tab-content pt-4 text-muted">
                                    <div className="tab-pane active" id="overview-tab" role="tabpanel">
                                        <div className="row">
                                            <Gallery />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            2024 © DualSysco.
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                Design &amp; Develop by DualSysco Research &amp; Development
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default ManageGallery