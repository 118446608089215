import React, { useState, useEffect, useContext } from 'react';
import PageTitle from "../../Components/PageTitle";
import { SubmitBtn } from "../../Components/InputElements";
import axios from 'axios';
import Swal from 'sweetalert2';
import { ConfigContext } from '../../Context/ConfigContext';
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import { formatDate } from "../../Components/GlobalFunctions";

const Items = () => {

    const [item_id, setitem_id] = useState(0)
    const { apiURL, apiHeaderJson, } = useContext(ConfigContext)
    const [item_name, setitem_name] = useState("");
    const [item_description, setitem_description] = useState("");
    const [rows, setRows] = useState([]);
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loadingList, setLoadingList] = useState(false);
    const [errors, setErrors] = useState({});
    const [keyword, setKeyword] = useState("");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        setLoadingList(true);
        GetList();
    }, [page]);

    const handleInputChange = (e, setStates) => {
        const { name, value } = e.target;
        setStates(value);
        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null,
            }));
        }

    };
    const handleFilter = () => {

    }
    const handleClear = () => {

    }

    const validateForm = () => {
        var errors = {};
        if (!item_name.trim()) {
            errors.item_name = 'Item name is requested';
        }

        if (!item_description.trim()) {
            errors.item_description = 'Item Description is requested';
        }
        if (Object.keys(errors).length !== 0) {
            setErrors(errors);
            return true;
        } else {
            return false;
        }
    };

    const GetInfo = (item_id) => {
        const headers = apiHeaderJson;
        try {

            axios
                .get(`${apiURL}api/items/${item_id}`, { headers })
                .then((response) => {

                    const { data, success } = response.data;
                    if (success === true) {
                        setitem_id(data.item_id);
                        setitem_name(data.item_name);
                        setitem_description(data.item_description);
                    } else {
                        console.error("No data or incorrect data structure.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching profile:", error);
                });
        } catch (error) {
            console.log(error);

        }
    };



    const GetList = () => {
        const headers = apiHeaderJson;
        try {
            axios
                .get(`${apiURL}api/items`, {
                    params: { limit, page },
                    headers,
                })
                .then((response) => {
                    const data = response.data;
                    if (data.success) {
                        setRows(data.data);
                        setNext(data.next);
                        setPrev(data.prev);
                        // setPage(data.page);
                        setTotalRecords(data.total_records);
                        setTotalPages(data.total_pages);
                    }
                    setLoadingList(false);
                });

        } catch (error) {
            console.log(error);

        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) return;

        try {
            const headers = apiHeaderJson;
            const Body = {
                item_name,
                item_description,
                created_by: localStorage.getItem('user_id'),
                created_date: formatDate(),
            };

            const requestMethod = item_id !== 0 ? 'put' : 'post';
            const url = `${apiURL}api/items${item_id !== 0 ? `/${item_id}` : ''}`;

            axios[requestMethod](url, Body, { headers })
                .then((response) => {
                    const { success } = response.data;

                    Swal.fire({
                        title: `<strong>${success ? 'Success' : 'Failed'}</strong>`,
                        text: item_id ? "Items Updated Successfully":"Items Created Sucessfully",
                        icon: success ? 'success' : 'error',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });

                    if (success) {
                        resetForm();
                        setLoadingList(true);
                        GetList();
                    } else {
                        setLoadingList(true);
                        GetList();
                    }
                });

        } catch (error) {
            console.log(error);

        }
    };


    const handleDelete = (id) => {
        try {
            const headers = apiHeaderJson;
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete(`${apiURL}api/items/${id}`, { headers })
                        .then((response) => {
                         

                            Swal.fire({
                                title: `<strong>${response.data.success ? 'Success' : 'Failed'}</strong>`,
                                text: response.data.message,
                                icon: response.data.success ? 'success' : 'error',
                                timer: 1000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                            });

                            if (response.data.success) {
                                setLoadingList(true);
                                GetList();
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                                title: "<strong>Error</strong>",
                                html: error.message || error,
                                icon: 'error',
                            });
                        });
                }
            });

        } catch (error) {
            console.log(error);

        }
    };


    const handlePrev = () => {
        if (prev) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleNext = () => {
        if (next) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleChange = (e) => {
        setPage(parseInt(e.target.value, 10));
    };

    const resetForm = () => {
        setitem_id(0);
        setitem_name("")
        setitem_description("")
        setErrors({})
    };


    return (
        <div className="main-content">
            <div className="page-content">
                {
                    loadingList === false ? (
                        <div className="container-fluid">
                            <br />
                            <PageTitle title={`Add Items`} primary={`Home`} />
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex" style={{ backgroundColor: "#257180" }}>
                                            <h4 className="card-title mb-0 flex-grow-1 text-white">Item</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row gy-4">
                                                <div className="col-md-12">
                                                    <form onSubmit={handleSubmit} className="row">
                                                        <div className="col-md-12">
                                                            <label htmlFor="item_name">Item Name</label>
                                                            <input
                                                                type="text"
                                                                id="item_name"
                                                                name="item_name"
                                                                value={item_name}
                                                                onChange={(e) => handleInputChange(e, setitem_name)}
                                                                className={`form-control ${errors.item_name ? 'is-invalid' : ''}`}
                                                                placeholder="Please Enter Item name"
                                                            />
                                                            {errors.item_name && <div className="text-danger">{errors.item_name}</div>}
                                                        </div>

                                                        <div className="col-md-12 mt-3">
                                                            <label htmlFor="item_description">Item Description</label>
                                                            <textarea
                                                                id="item_description"
                                                                name="item_description"
                                                                value={item_description}
                                                                onChange={(e) => handleInputChange(e, setitem_description)}
                                                                className={`form-control ${errors.item_description ? 'is-invalid' : ''}`}
                                                                placeholder="Please Enter Item Description"
                                                                rows="4" 
                                                            />
                                                            {errors.item_description && <div className="text-danger">{errors.item_description}</div>}
                                                        </div>


                                                        <div className="col-md-12" style={{ marginTop: "30px" }}>
                                                            {
                                                                item_id !== 0 ?
                                                                    <SubmitBtn icon={`ri-save-line`} text={`Update`} type={`primary`} /> :
                                                                    <SubmitBtn text={`Save`} type={`primary`} icon={`ri-save-line`} />
                                                            }
                                                            {
                                                                item_id ? <button style={{ float: 'right' }} type="button" className={`btn btn-light right btn-label`} onClick={resetForm} ><i className={` ri-arrow-right-line label-icon align-middle fs-16 ms-2`} />New</button> : <div></div>

                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className='card'>
                                        <div className="col-md-12">
                                            <div
                                                className='card-header align-items-center d-flex'
                                                style={{
                                                    background: "#257180",
                                                }}>
                                                <h4 className='card-title mb-0 flex-grow-1 text-white'>Items List</h4>

                                            </div>
                                            <div className='card-header'>
                                                <div className=" pt-0 pb-0" style={{ border: "none" }}>
                                                    <div className="row py-4  pt-2">
                                                        <div className="col-md-5">
                                                            <div className="input-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="keyword"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="button-addon2"
                                                                    placeholder="Please Enter Keyword"
                                                                    value={keyword}
                                                                    onChange={handleInputChange}
                                                                />
                                                                <span
                                                                    className='mdi mdi-magnify search-widget-icon'
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: "10px",
                                                                        top: "50%",
                                                                        transform: "translateY(-50%)",
                                                                        fontSize: "4vh",
                                                                        color: "#B1B1B6",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-5">
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <table className={`table table-bordered table-stripped`}>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th className='text-center'>Item Name</th>
                                                            <th className='text-center'>Item Description</th>
                                                            <th className='text-center' width={120}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    {rows.length !== 0 ? (
                                                        <tbody>
                                                            {rows.map((row) => (
                                                                <tr key={row.item_id}>
                                                                    
                                                                    <td className='text-center'>{row.item_name}</td>
                                                                    <td className='text-center'>{row.item_description}</td>
                                                                    <td className='d-flex justify-content-around'>
                                                                        <button
                                                                            type='button'
                                                                            onClick={() => GetInfo(row.item_id)}
                                                                            className="btn-icon btn btn-sm btn-outline-primary"
                                                                        >
                                                                            <i className="ri-edit-box-line"></i>
                                                                        </button>
                                                                        <button
                                                                            onClick={() => handleDelete(row.item_id)}
                                                                            className="btn-icon btn btn-sm btn-outline-danger"
                                                                        >
                                                                            <i className="ri-delete-bin-line"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="4">
                                                                    <NoRecords />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                    <tfoot className='table-light'>
                                                        <tr>
                                                            <th colSpan={7} >
                                                                <div className="d-flex justify-content-between" >
                                                                    <button disabled={prev === false && loadingList === false ? true : false} type="button" onClick={() => handlePrev()} className={`btn btn-warning btn-label waves-effect waves-light`}><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" /> Previous</button>
                                                                    <div className='col-md-4' style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {
                                                                            <small>Total Records: {totalRecords} | Total Pages: {totalPages} | Current Page: {page}</small>
                                                                        }
                                                                    </div>
                                                                    <div className='col-md-2'>
                                                                        <select className="form-select" onChange={(e) => handleChange(e)}>
                                                                            {Array.from({ length: totalPages }, (_, i) => (
                                                                                <option key={i + 1} selected={page === i + 1} value={i + 1}>
                                                                                    {i + 1}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <button disabled={next === false && loadingList === false ? true : false} type="button" onClick={() => handleNext()} className={`btn btn-primary btn-label waves-effect right waves-light`}><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" /> Next</button>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        (
                            <ContentLoader />
                        )
                }
            </div>
        </div>
    )
}

export default Items