import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { formatDate } from "../GlobalFunctions";
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
const Documents = () => {
  const { projectId } = useParams();
  const { apiURL, apiHeaderFile, apiHeaderJson, documentURL } =
    useContext(ConfigContext);
  const [fileData, setFileData] = useState([]);

  const handleGetFile = async () => {
    const headers = apiHeaderFile;
    try {
      const response = await axios.get(
        `${apiURL}api/project-media/project/${projectId}`,
        headers
      );
      const { data, success } = response.data;
      if (success) {
        setFileData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkIcon = (type) => {
    if (type === "IMAGE") return "ri-image-2-fill";
    if (type === "DOCUMENT") return "ri-file-pdf-fill";
    return "ri-file-zip-fill";
  };

  //   <li>
  //     <a
  //       className="dropdown-item"
  //       onClick={() => downloadDocument("url", "document_path")}
  //     >
  //       <i className="ri-download-2-fill me-2 align-middle text-muted" />
  //       Download
  //     </a>
  //   </li>;

  const downloadDocument = (url, filename) => {
    fetch(`${url}${filename}`)
      .then((response) => response.blob())
      .then((blob) => {
        const newBlobURL = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = newBlobURL;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(newBlobURL);
      })
      .catch((error) => console.error("Download failed:", error));
  };

  const viewDocument = (type, filename) => {
    if (type === "IMAGE") {
      return `https://tms.atctransline.in/apis/public/images/${filename}`;
    }
    if (type === "DOCUMENT") {
      return `https://tms.atctransline.in/apis/public/documents/${filename}`;
    }
  };

  const handleSubmit = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Upload File",
      html: `
            <div class="container">
            <div class="row">
            <div class="col-12 mt-3">
            <div class="row">
                    <label>Document Type:</label><br>
                    <div class="col-6">
                        <input type="radio" id="pmTypeImage" name="pmType" class="form-check-input" value="IMAGE" />
                        <label class="form-check-label" style="font-size:13px;"  for="pmTypeImage">IMAGE</label>
                    </div>
                    <div class="col-6">
                        <input type="radio" id="pmTypeDocument" name="pmType" class="form-check-input" value="DOCUMENT" />
                        <label class="form-check-label" style="font-size:13px;"  for="pmTypeDocument">PDF</label>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
            <input type="text" id="pmTitle" class="form-control" placeholder=" Title" />
            </div>
                <div class="col-12 mt-3">
                    <input type="text" id="pmGeotag" class="form-control" placeholder="Geotag (lat,long)" />
                </div>
                <div class="col-12 mt-3">
                <input type="file" id="fileInput" class="form-control" accept="application/pdf,image/*" />
                </div>
                </div>
                </div>
            `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      preConfirm: () => {
        const fileInput = document.getElementById("fileInput");
        const pmTitle = document.getElementById("pmTitle").value.trim();
        const pmGeotag = document.getElementById("pmGeotag").value.trim();

        const file = fileInput.files[0];
        if (!file) {
          Swal.showValidationMessage("Please select a file");
          return false;
        }
        if (file.size > 10 * 1024 * 1024) {
          Swal.showValidationMessage("File size must be less than 10 MB");
          return false;
        }

        // Check file type
        const pmType = document.querySelector('input[name="pmType"]:checked');
        if (!pmType) {
          Swal.showValidationMessage("Please select a document type");
          return false;
        }

        // Validate file type based on selected document type
        const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
        const validPdfType = "application/pdf";

        if (pmType.value === "IMAGE" && !validImageTypes.includes(file.type)) {
          Swal.showValidationMessage("Please upload a valid image (JPEG/PNG)");
          return false;
        }

        if (pmType.value === "DOCUMENT" && file.type !== validPdfType) {
          Swal.showValidationMessage("Please upload a valid PDF");
          return false;
        }

        // Validate title with regex
        const titleRegex = /^[a-zA-Z0-9][a-zA-Z0-9 _-]*[a-zA-Z0-9]$/;
        if (!pmTitle) {
          Swal.showValidationMessage("Please enter the title");
          return false;
        }
        if (!titleRegex.test(pmTitle)) {
          Swal.showValidationMessage(
            "Invalid title. Title must start and end with an alphanumeric character and can include spaces, underscores, and dashes."
          );
          return false;
        }

        // Validate geotag with regex
        const geotagRegex =
          /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?((1[0-7]\d|[1-9]?\d)(\.\d+)?|180(\.0+)?)$/;
        if (!pmGeotag) {
          Swal.showValidationMessage("Please enter geo tag");
          return false;
        }
        if (!geotagRegex.test(pmGeotag)) {
          Swal.showValidationMessage(
            "Invalid geotag. Format: latitude,longitude (e.g., 12.34,56.78)."
          );
          return false;
        }

        return { file, pmTitle, pmType: pmType.value, pmGeotag };
      },
    });

    if (!formValues) return;

    try {
      const headers = apiHeaderFile;
      const formData = new FormData();
      formData.append("file", formValues.file);
      formData.append("pm_project_id", projectId);
      formData.append("pm_title", formValues.pmTitle);
      formData.append("pm_type", formValues.pmType);
      formData.append("pm_geotag", formValues.pmGeotag);
      formData.append("created_by", localStorage.getItem("user_id"));
      formData.append("created_date", formatDate());

      const url = `${apiURL}api/project-media`;

      const response = await axios.post(url, formData, { headers });
      const { success, message, data } = response.data;

      Swal.fire({
        title: `<strong>${success ? "Success" : "Failed"}</strong>`,
        text: "Media Uploaded Successfully",
        icon: success ? "success" : "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      if (success) {
        setFileData((prevData) => [...prevData, data]);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while uploading the file. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const hanldeDelete = async (id) => {
    try {
      const headers = apiHeaderJson;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`${apiURL}api/project-media/${id}`, { headers })
            .then((response) => {
              Swal.fire({
                title: `<strong>${
                  response.data.success ? "Success" : "Failed"
                }</strong>`,
                text: response.data.message,
                icon: response.data.success ? "success" : "error",
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
              });

              if (response.data.success) {
                setFileData([]);
                handleGetFile();
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "<strong>Error</strong>",
                html: error.message || error,
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetFile();
  }, []);

  return (
    <div className="col-xxl-12">
      <div className="card">
        <div className="card-body">
          <div className="card-header d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
            <div className="flex-shrink-0">
              <button
                type="button"
                onClick={handleSubmit}
                htmlFor="formFile"
                className="btn btn-danger"
              >
                <i className="ri-upload-2-fill me-1 align-bottom" /> Upload File
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table table-borderless align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">File Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Title</th>
                      <th scope="col">GeoTag</th>
                      <th scope="col">Upload Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fileData?.length === 0 ? (
                      <tr>
                        <td colSpan="6">
                          <NoRecords />
                        </td>
                      </tr>
                    ) : (
                      fileData?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm">
                                <div className="avatar-title bg-primary-subtle text-primary rounded fs-20">
                                  <i className={checkIcon(item.pm_type)} />
                                </div>
                              </div>
                              <div className="ms-3 flex-grow-1">
                                <h6 className="fs-14 mb-0">
                                  <a
                                    href="javascript:void(0)"
                                    className="text-body"
                                  >
                                    {item.pm_file_name}
                                  </a>
                                </h6>
                              </div>
                            </div>
                          </td>
                          <td>{item.pm_type}</td>
                          <td>{item.pm_title}</td>
                          <td>{item.pm_geotag}</td>
                          <td>{item.created_date}</td>
                          <td>
                            <div className="dropdown">
                              <a
                                href="javascript:void(0);"
                                className="btn btn-light btn-icon"
                                id="dropdownMenuLink15"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i className="ri-equalizer-fill" />
                              </a>
                              <ul
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="dropdownMenuLink15"
                              >
                                {/* <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill me-2 align-middle text-muted" />View</a></li > */}
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    className="dropdown-item"
                                    target="_blank"
                                    href={viewDocument(
                                      `${item.pm_type}`,
                                      `${item.pm_file_name}`
                                    )}
                                  >
                                    <i className="ri-eye-fill me-2 align-middle text-muted" />
                                    View
                                  </a>
                                </li>
                                {/* <li className="dropdown-divider" /> */}
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>
                                      hanldeDelete(item.project_media_id)
                                    }
                                  >
                                    <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
