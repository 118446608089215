export function TimeFormater(datetimeString) {
  if (!datetimeString) {
    return "";
  }
  var timeString = datetimeString?.split(" ")[1];
  if (timeString) {
    var timeComponents = timeString?.split(":");
    var hours = parseInt(timeComponents[0]);
    var minutes = parseInt(timeComponents[1]);
    var meridiem = hours < 12 ? "AM" : "PM";
    hours = hours % 12 === 0 ? 12 : hours % 12;
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var formattedTime = hours + ":" + minutes + " " + meridiem;
    return formattedTime;
  } else {
    timeString = datetimeString?.split("T")[1];
    var timeComponents = timeString?.split(":");
    var hours = parseInt(timeComponents[0]);
    var minutes = parseInt(timeComponents[1]);
    var meridiem = hours < 12 ? "AM" : "PM";
    hours = hours % 12 === 0 ? 12 : hours % 12;
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var formattedTime = hours + ":" + minutes + " " + meridiem;
    return formattedTime;
  }
}

export function DateFormater(GettedDate) {
  if (!GettedDate) {
    return ` `;
  }

  const date = new Date(GettedDate);
  if (isNaN(date.getTime())) {
    return ` `;
  }

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get the day, month, and year
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Return the formatted date string, with padded day
  return `${day.toString().padStart(2, "0")}-${month}-${year}`;
}

export function formatNumber(num) {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k";
  } else {
    return num;
  }
}

export function formatDate() {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatDateRange(startDate, endDate) {
  const format = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = String(d.getFullYear()).slice(-2);
    return `${month}/${year}`;
  };

  return `${format(startDate)} - ${format(endDate)}`;
}

export function formatDateTime(today) {
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0"); // Corrected method name
  const hours = today.getHours().toString().padStart(2, "0");
  const minutes = today.getMinutes().toString().padStart(2, "0");
  const seconds = today.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
