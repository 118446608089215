import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import Swal from "sweetalert2";

const TopBar = () => {
  const { projectId } = useParams();

  const [status, setStatus] = useState(0);
  const { apiURL, apiHeaderJson } = useContext(ConfigContext);

  const navigate = useNavigate();
  const DoNavigate = (route) => {
    navigate(route);
  };

  const handlRadioChange = (value) => {
    setStatus(value);
  };
  const handleStatus = async () => {
    const headers = apiHeaderJson;
    const body = {
      project_status: status,
      project_id: projectId,
      created_by: localStorage.getItem("user_id"),
    };
    try {
      const response = await axios.put(
        `${apiURL}api/projects/${projectId}`,
        body,
        { headers }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Project Status Updated Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        document.getElementById("close-modal")?.click();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const GetProfileInfo = async () => {
    try {
      const response = await axios.get(`${apiURL}api/projects/${projectId}`);
      const data = response.data;
      if (data.success) {
        setStatus(data.data.project_status || 0);
      } else {
        setStatus("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetProfileInfo();
  }, []);

  return (
    <div className="d-flex profile-wrapper">
      <ul
        className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <NavLink
            to={"/ProjectProfile"}
            onClick={() => DoNavigate(`/ProjectProfile/${projectId}`)}
            className="nav-link fs-14"
            data-bs-toggle="tab"
            href="#overview-tab"
            role="tab"
            aria-selected="true"
          >
            <i className="ri-airplay-fill d-inline-block d-md-none" />{" "}
            <span className="d-none d-md-inline-block">Overview</span>
          </NavLink>
        </li>
        <li className="nav-item" role="presentation">
          <NavLink
            to={"/Documents"}
            onClick={() => DoNavigate(`/Documents/${projectId}`)}
            className="nav-link fs-14"
            data-bs-toggle="tab"
            href="#documents"
            role="tab"
            aria-selected="false"
            tabIndex={-1}
          >
            <i className="ri-folder-4-line d-inline-block d-md-none" />
            <span className="d-none d-md-inline-block">Documents</span>
          </NavLink>
        </li>
        <li className="nav-item" role="presentation">
          <NavLink
            to={"/ContractorList"}
            onClick={() => DoNavigate(`/ContractorList/${projectId}`)}
            className="nav-link fs-14"
            data-bs-toggle="tab"
            href="#contractor"
            role="tab"
            aria-selected="false"
            tabIndex={-1}
          >
            <i className="ri-list-unordered d-inline-block d-md-none" />
            <span className="d-none d-md-inline-block">Contractor List</span>
          </NavLink>
        </li>
        <li className="nav-item" role="presentation">
          <NavLink
            to={"/ConsultantList"}
            onClick={() => DoNavigate(`/ConsultantList/${projectId}`)}
            className="nav-link fs-14"
            data-bs-toggle="tab"
            href="#consultant"
            role="tab"
            aria-selected="false"
            tabIndex={-1}
          >
            <i className="ri-list-unordered d-inline-block d-md-none" />{" "}
            <span className="d-none d-md-inline-block">Consultant List</span>
          </NavLink>
        </li>
        <li className="nav-item" role="presentation">
          <NavLink
            to={"/Gallery"}
            onClick={() => DoNavigate(`/Gallery/${projectId}`)}
            className="nav-link fs-14"
            data-bs-toggle="tab"
            href="#Gallery"
            role="tab"
            aria-selected="false"
            tabIndex={-1}
          >
            <i className="ri-gallery-line d-inline-block d-md-none" />{" "}
            <span className="d-none d-md-inline-block">Gallery</span>
          </NavLink>
        </li>

        <li className="nav-item" role="presentation">
          <NavLink
            to={"/Task"}
            onClick={() => DoNavigate(`/Task/${projectId}`)}
            className="nav-link fs-14"
            data-bs-toggle="tab"
            href="#Task"
            role="tab"
            aria-selected="false"
            tabIndex={-1}
          >
            <i className=" ri-todo-line d-inline-block d-md-none" />{" "}
            <span className="d-none d-md-inline-block">Task</span>
          </NavLink>
        </li>
      </ul>
      <div className="flex-shrink-0 mx-3">
        <button
          className="btn btn-success"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
        >
          <i className="ri-edit-box-line align-bottom" /> Update Status
        </button>
      </div>
      {/* <div className="flex-shrink-0">
        <Link to="/EditProfile" className="btn btn-success">
          <i className="ri-edit-box-line align-bottom" /> Edit Profile
        </Link>
      </div> */}

      <div
        id="myModal"
        className="modal fade"
        tabIndex={-1}
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        style={{ display: "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myModalLabel">
                Update Project Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {/* <p>Please Select Project Status </p> */}
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="Active"
                    name="status"
                    checked={status === 0}
                    onChange={() => handlRadioChange(0)}
                  />
                  &nbsp;{" "}
                  <label for="Active" style={{ cursor: "pointer" }}>
                    Active
                  </label>
                  <br />
                </div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="In-Active"
                    name="status"
                    checked={status === 1}
                    onChange={() => handlRadioChange(1)}
                  />
                  &nbsp;{" "}
                  <label for="In-Active" style={{ cursor: "pointer" }}>
                    In-Active
                  </label>
                  <br />
                </div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="Completed"
                    name="status"
                    checked={status === 2}
                    onChange={() => handlRadioChange(2)}
                  />
                  &nbsp;{" "}
                  <label for="Completed" style={{ cursor: "pointer" }}>
                    Completed
                  </label>
                  <br />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success "
                onClick={handleStatus}
              >
                Update
              </button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </div>
  );
};

export default TopBar;
