import React, { useState, useEffect, useContext, useCallback } from "react";
import PageTitle from "../../Components/PageTitle";
import axios from "axios";
import Swal from "sweetalert2";
import { ConfigContext } from "../../Context/ConfigContext";
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';

const Approvals = ({ customStyles }) => {
    const { apiURL, apiHeaderJson, apiHeaderFile } = useContext(ConfigContext);
    const [slider_id, setslider_id] = useState(0);
    const [selectOption, setSelectOption] = useState(null);
    const [rows, setRows] = useState([]);
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loadingList, setLoadingList] = useState(false);
    const [keyword, setKeyword] = useState("");
    const [reload, setReload] = useState(false)
    const [errors, setErrors] = useState({});
    const [dateRange, setDateRange] = useState([null, null])


    useEffect(() => {
        setLoadingList(true);
        GetList();
    }, [page]);

    const handleInputChange = (e, setStates) => {
        const { name, value } = e.target;
        setStates(value);
        if (errors[name]) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: null,
            }));
        }

    };






    const GetList = () => {
        try {
            const headers = apiHeaderJson;
            axios
                .get(`${apiURL}api/projects`, {
                    params: { limit, page },
                    headers,
                })
                .then((response) => {
                    const data = response.data;
                  

                    if (data.success) {

                        setRows(data.data);
                        setNext(data.next);
                        setPrev(data.prev);
                        setPage(data.page);
                        setTotalRecords(data.total_records);
                        setTotalPages(data.total_pages);
                    }
                    setLoadingList(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingList(false);
                });
        } catch (error) {
            console.log(error);
            setLoadingList(false);
        }
    };





    const handlePrev = () => {
        if (prev) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleNext = () => {
        if (next) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleChange = (e) => {
        setPage(parseInt(e.target.value, 10));
    };



    const handleViewImg = (imageUrl, textTitle) => {
        Swal.fire({
            title: textTitle,
            text: "",
            imageUrl: `${imageUrl}`,
            imageAlt: "Custom image",
        });
    };

   
   


    const options = [
        { value: '0', label: "Pending" },
        { value: '1', label: 'Assigned' },
        { value: '2', label: 'Completed' }
    ];

    const handleSelectStatus = (selectedOption) => {
        setSelectOption(selectedOption ? selectedOption.value : '');
    };

    const GetStatus = (status) => {
        if (status == '0') {
            return "Pending"
        } else if (status == '1') {
            return "Assigned"
        } else if (status == '2') {
            return "Completed"
        }
    }

    const GetBadge = (status) => {
        if (status == '0') {
            return "badge bg-warning-subtle text-warning"
        } else if (status == '1') {
            return "badge bg-info-subtle text-info"
        } else if (status == '2') {
            return "badge bg-success-subtle text-success"
        }

    }


    useEffect(() => {
        reload && GetList()
    }, [reload])

    return (
        <div className="main-content">
            <div className="page-content">
                {loadingList === false ? (
                    <div className="container-fluid">
                        <br />
                        <PageTitle title={`Approvals`} primary={`Home`} />
                        <div className="row">

                            <div className="col-md-12">
                                <div className="card">
                                    <div
                                        className='card-header align-items-center d-flex'
                                        style={{
                                            background: "#257180",
                                        }}>
                                        <h4 className='card-title mb-0 flex-grow-1 text-white'>Approval List</h4>



                                    </div>

                                    <div className="col-md-12">

                                        <div className="card-header pt-0 pb-0" style={{ border: "none" }}>
                                            <div className="row py-4 pb-2">
                                                <div className="col-md-3">
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="keyword"
                                                            aria-label="Recipient's username"
                                                            aria-describedby="button-addon2"
                                                            placeholder="Please Enter Keyword"
                                                            value={keyword}
                                                            onChange={handleInputChange}
                                                        />
                                                        <span
                                                            className='mdi mdi-magnify search-widget-icon'
                                                            style={{
                                                                position: "absolute",
                                                                right: "10px",
                                                                top: "50%",
                                                                transform: "translateY(-50%)",
                                                                fontSize: "4vh",
                                                                color: "#B1B1B6",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="position-relative">
                                                        <Flatpickr
                                                            placeholder="Please Select Date"
                                                            data-enable-time
                                                            className="form-control date-picker mb-3 ps-3"                                                             value={dateRange}
                                                            onChange={(selectedDates) => {
                                                                setDateRange(selectedDates);
                                                            }}
                                                            options={{
                                                                mode: 'range',
                                                                dateFormat: 'Y-m-d',
                                                            }}
                                                        />

                                                        <span
                                                            className="ri-calendar-2-line"
                                                            style={{
                                                                position: "absolute",
                                                                right: "10px",
                                                                top: "50%",
                                                                transform: "translateY(-50%)",
                                                                fontSize: "4vh",
                                                                color: "#B1B1B6",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <Select
                                                        options={options}
                                                        value={selectOption ? options.find(option => option.value === selectOption) : null}
                                                        onChange={handleSelectStatus}
                                                        styles={customStyles}
                                                        placeholder="Please Select Status"
                                                    />
                                                </div>


                                                <div className="col-md-3">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-9">

                                                            <div className="dropdown">
                                                                <button type="button" className="btn btn-light btn-label waves-effect waves-light " id="dropdownMenuLink4" data-bs-toggle="dropdown" aria-expanded="true"
                                                                >
                                                                    <i className="ri-download-2-line label-icon align-middle fs-16 me-2" />
                                                                    Download Report
                                                                </button>
                                                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink4">
                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-printer-line me-2 align-middle text-muted" />Print</a>
                                                                    </li>
                                                                    <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-file-pdf-line me-2 align-middle text-muted" />PDF</a>
                                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-file-excel-2-line me-2 align-middle text-muted" />Excel</a>
                                                                        </li>

                                                                    </li>
                                                                </ul>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        <div className="card-body pt-0">
                                            <table
                                                className={`table table-bordered table-stripped`}
                                            >
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="text-center" width="%">Project Name</th>
                                                        <th className="text-center" width="%">Location</th>
                                                        <th className="text-center" width="%">Start Date</th>
                                                        <th className="text-center" width="%">End Date</th>
                                                        <th className="text-center" width="%">Status</th>

                                                        <th className="text-center" width="%">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {rows.length !== 0 ? (
                                                    <tbody>
                                                        {rows.map((row) => (
                                                            <tr key={row.project_id}>
                                                                <td className="text-center">
                                                                    <NavLink to={`/ProjectProfile/${row.project_id}`}>{row.project_title}</NavLink>
                                                                </td>
                                                                <td className="text-center">{row.project_address}</td>
                                                                <td className="text-center">{row.project_start_date}</td>
                                                                <td className="text-center">{row.project_end_date}</td>

                                                                <td className='text-center'><span className={GetBadge(row.project_status)}>{GetStatus(row.project_status)}</span></td>


                                                                <td className="d-flex justify-content-around">
                                                                    <NavLink
                                                                        to={`/RequestList/${row.project_id}`}
                                                                        className="btn btn-info btn-label waves-effect right waves-light btn-sm"
                                                                    >
                                                                        <i className=" bx bx-list-ul label-icon align-middle fs-16 ms-2"></i>
                                                                        Request List
                                                                    </NavLink>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>

                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="4">
                                                                <NoRecords />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                <tfoot className="table-light">
                                                    <tr>
                                                        <th colSpan={8}>
                                                            <div className="d-flex justify-content-between">
                                                                <button
                                                                    disabled={
                                                                        prev === false && loadingList === false
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    type="button"
                                                                    onClick={() => handlePrev()}
                                                                    className={`btn btn-warning btn-label waves-effect waves-light`}
                                                                >
                                                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" />{" "}
                                                                    Previous
                                                                </button>
                                                                <div
                                                                    className="col-md-4"
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        <small>
                                                                            Total Records: {totalRecords} | Total
                                                                            Pages: {totalPages} | Current Page:{" "}
                                                                            {page}
                                                                        </small>
                                                                    }
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <select
                                                                        className="form-select"
                                                                        onChange={(e) => handleChange(e)}
                                                                    >
                                                                        {Array.from(
                                                                            { length: totalPages },
                                                                            (_, i) => (
                                                                                <option
                                                                                    key={i + 1}
                                                                                    selected={page === i + 1}
                                                                                    value={i + 1}
                                                                                >
                                                                                    {i + 1}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <button
                                                                    disabled={
                                                                        next === false && loadingList === false
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    type="button"
                                                                    onClick={() => handleNext()}
                                                                    className={`btn btn-primary btn-label waves-effect right waves-light`}
                                                                >
                                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" />{" "}
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ) : (
                    <ContentLoader />
                )}
            </div>
        </div>
    );
};

export default Approvals