import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Page404 from "./Layout/Page404";
import { ConfigProvider } from "../src/Context/ConfigContext";
import routes from "./Routes/Routes";
import AppLayout from "./Layout/AppLayout";
import Login from "./Pages/Login";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { jwtDecode } from "jwt-decode"; // Adjusted to the correct import for jwt-decode
import ProtectedRoute from "./Routes/ProtectedRoute";
import { useEffect } from "react";
import { generateToken ,messaging} from "./Notification/FireBase";
import { onMessage } from "firebase/messaging";

const isTokenValid = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);

    if (!decodedToken || !decodedToken.exp) {
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    if (decodedToken.exp < currentTime) {
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error while validating token:", error);
    return false;
  }
};

function App() {
  useEffect(() => {
    generateToken();
    onMessage(messaging,(payload) =>{
      console.log(payload,"Payload");
      
    })
  }, []);
  const mRoutes = isTokenValid()
    ? [
        {
          element: (
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          ),
          errorElement: <Page404 />,
          children: routes,
        },
      ]
    : [
        { path: "/", element: <Login /> },
        { path: "*", element: <Login /> },
      ];

  const router = createBrowserRouter(mRoutes);

  // const router = createBrowserRouter(mRoutes, {
  //   basename: "/apps/admin/", // Set your subdirectory path here
  // });

  return (
    <ConfigProvider>
      <RouterProvider router={router} />
      {/* <ToastContainer /> */}
    </ConfigProvider>
  );
}

export default App;
